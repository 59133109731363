import React, { useState, forwardRef, useImperativeHandle, useContext, useRef, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';

import { IntegerInput } from '../../../../components';

import { ATALHO_AVANCAR, ATALHO_VOLTAR, SECONDARY_COLOR, SECONDARY_COLOR_OFFLINE } from '../../../../utils'
import { AppContext } from '../../../../app/context';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ESC_ACTION = 'Escape';
const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;

const CONSULTOR_PRECOS_ACTION = 'F3';

const DialogoCategoriaTransacao = forwardRef(({ handleDelete, openConsultorPrecos= () => {} }, ref) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(0);
  const { app: { modoOffline } } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const refOrdem = useRef(null)

  const handleCloseDialog = () => {
    setOpen(false);
  };

  async function handleSalvar() {
    if(ordem === '') {
      handleDelete(id);
      handleCloseDialog();
    } else {
      enqueueSnackbar(`Valor inválido para a ordem!!!`, {
        variant: 'error',
      });
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setId(0);
      setOpen(true);
    },
  }));

  function validateOrdem() {
    let error = '';
    if (id <= 0 || Number.isNaN(id)) {
      error = 'A ordem tem que ser maior que zero!!!';
    }
    return error;
  }

  const ordem = validateOrdem()

  function handleActions(action) {
    switch (action) {
      case ESC_ACTION:
        handleCloseDialog()
        break;
      case CANCELAR_VENDA_ACTION:
        handleCloseDialog()
        break;
      case FECHAR_VENDA_ACTION: {
        handleSalvar()
        break;
      }
      case CONSULTOR_PRECOS_ACTION:
        openConsultorPrecos();
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if(open) {
        if(refOrdem.current) {
          refOrdem.current.focus();
          refOrdem.current.select();
        }
      }
    }, 200);

    return () => clearTimeout(timeoutId);
  }, [open])

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{
            backgroundColor: modoOffline ? SECONDARY_COLOR_OFFLINE : SECONDARY_COLOR, 
            color: 'white',
          }}
        >
          Deletar Pesagem
        </DialogTitle>
        <DialogContent
          style={{
            //padding: '20px 36px 10px',
            margin: '0',
          }}
        >
          <IntegerInput
            ref={refOrdem}
            handleEnter={() => {
              handleSalvar()
            }}
            label="Ordem"
            handleKey={(keyCode, keyName) => {
              handleActions(keyName);
            }}
            value={
              Number.isNaN(id) ? '' : String(id)
            }
            onChange={(value) =>
              setId(parseInt(value.target.value, 10))
            }
            error={ordem !== ''}
            helperText={ordem}
            fullWidth
            autoFocus
          />
        </DialogContent>
        <DialogActions
          style={{
            //padding: '32px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={handleCloseDialog}
            variant="contained"
            color="secondary"
            tabindex="-1"
          >
            {`Cancelar (${CANCELAR_VENDA_ACTION})`}
          </Button>
          <Button
            onClick={handleSalvar}
            variant="contained"
            color="secondary"
            tabindex="-1"
          >
            {`Deletar (${FECHAR_VENDA_ACTION})`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
