import {
  renameItensUIDD,
  getSubTotal,
} from '../../utils';

const reducer = (state, action) => {
  let arrayNew = state.itens.slice();
  switch (action.type) {
    case 'ADD_ITEM':
      return {
        ...state,
        itens: [...arrayNew, action.item],
        subTotal: getSubTotal([...arrayNew, action.item]),
      };
    case 'REMOVE_ITEM':
      const removeItens = []
      for (let i = 0; i < action.indices.length; i += 1) {
        arrayNew = arrayNew.filter((obj) => {
          if(obj.uidd !== action.indices[i]) {
            return true
          } else {
            removeItens.push(obj)
            return false
          }
        });
      }
      const itensExcluidosAntigos = [...state.itensExcluidos, ...removeItens]
      return {
        ...state,
        itens: [...renameItensUIDD(arrayNew)],
        itensExcluidos: [...renameItensUIDD(itensExcluidosAntigos)],
        subTotal: getSubTotal([...arrayNew]),
      };
    case 'UPDATE_ITEMS':
      return {
        ...state,
        itens: [...renameItensUIDD(action.itens)],
        subTotal: getSubTotal([...action.itens]),
      };

    case 'UPDATE_CLIENTE':
      return {
        ...state,
        cliente: action.cliente,
      };

    case 'ADD_ITENS_LIBERACOES': {
      return {
        ...state,
        itens: [...renameItensUIDD([...arrayNew, ...action.liberacoes])],
        subTotal: getSubTotal([...arrayNew, ...action.liberacoes]),
      };
    }
    default:
      return state;
  }
};

export default reducer;
