import React from 'react';

import { Paper, Box, makeStyles } from '@material-ui/core';

import {
  getNivelCaixaQtde,
  getQuantidadeCaixariaLabel
} from '../../../utils'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '10px',
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    boxSizing: 'border-box',
    opacity: '0.75',
  },
  containerInterno: {
    marginTop: '10px',
    fontSize: '35px',
    padding: '10px 10px',
  },
}));

const LabelAtendente = ({ produto }) => {
  const classes = useStyles();

  function getUnidadesCaixa() {
    if (produto) {
      return getQuantidadeCaixariaLabel(produto, produto.nivelCaixa)
    } else {
      return '-';
    }
  }

  function getNumeroNiveisCaixaria() {
    let numero = 0;
    if(produto) {
      numero = getNivelCaixaQtde(produto)
    }
    return numero
  }

  const numeroNiveisCaixaria = getNumeroNiveisCaixaria()

  return (
    <Paper
      className={classes.container}
      elevation={3}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        color="white"
      >
        <div style={{ textAlign: 'center' }}>{`CAIXARIA ${numeroNiveisCaixaria ? `(${numeroNiveisCaixaria} Níveis)` : ``}`}</div>

        <Paper className={classes.containerInterno} elevation={3}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width="100%"
            color="black"
          >
            {numeroNiveisCaixaria ? `${getUnidadesCaixa()}` : 'Nenhuma'}
          </Box>
        </Paper>
      </Box>
    </Paper>
  );
};

export default LabelAtendente;
