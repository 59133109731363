import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useState,
  useContext
} from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { api, getUsername } from '../../../services';
import { SECONDARY_COLOR, ATALHO_AVANCAR, ATALHO_VOLTAR, SECONDARY_COLOR_OFFLINE } from '../../../utils';
import {
  IntegerInput,
  TextInput,
} from '../../Inputs';

import { AppContext } from '../../../app/context';

const inputs = [
  {
    nome: 'numeroItem',
    defaultValue: -1,
    label: 'Código Item',
  },
  {
    nome: 'senha',
    defaultValue: '',
    label: 'Senha',
  },
];

const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const ESC_ACTION = 'Escape';

const CONSULTOR_PRECOS_ACTION = 'F3';

const DialogoNota = forwardRef(({
  handleCancelar, handleSalvar, title = 'titulo', itens, enqueueSnackbar, mensagemRemovido = 'Item removido com sucesso!', openConsultorPrecos= () => {}
}, ref) => {
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [numeroItem, setNumeroItem] = useState(Number.NaN);

  const { app: { modoOffline } } = useContext(AppContext);

  const refs = useRef([]);

  refs.current = inputs.map(
    (ref, index) => refs.current[index] = React.createRef(),
  );

  const handleChange = (event) => {
    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setPassword('');
    handleCancelar(password);
  };

  async function handleSalvarDialog() {
    try {
      if (!erroExistente) {
        const response = await api.post('/confere_senha', {
          username: getUsername(),
          password
        });
        if (response.data.status) {
          handleSalvar(numeroItem);
          setOpen(false);
          setPassword('');
          enqueueSnackbar(mensagemRemovido, {
            variant: 'success',
          });
        } else {
          setOpen(false);
          setPassword('');
          enqueueSnackbar('Senha incorreta!', {
            variant: 'error',
          });
        }
      }
    } catch(e) {
      enqueueSnackbar('INTERNET: ERRO AO CONFERIR A SENHA!', {
        variant: 'error',
      });
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
      setPassword('');
      setNumeroItem(Number.NaN);
    },
  }));

  function handleKey(action, name) {
    switch (name) {
      case FECHAR_VENDA_ACTION:
        handleSalvarDialog();
        break;
      case CANCELAR_VENDA_ACTION:
        handleCloseDialog();
        break;
      case ESC_ACTION:
        handleCloseDialog();
        break;
      case CONSULTOR_PRECOS_ACTION:
        openConsultorPrecos();
        break;
      default:
        break;
    }
  }

  function getRefNextInput(index) {
    let position = -1;
    switch (index) {
      case 0:
        position = 1;
        break;
      case 1:
        position = 0;
        break;
      default:
        break;
    }
    return position;
  }

  function handleNextInput(index) {
    const position = getRefNextInput(index);
    if (position !== -1) {
      if (refs.current[position].current) {
        refs.current[position].current.focus();
        refs.current[position].current.select();
      }
    } else {
      handleSalvarDialog();
    }
  }

  function validateNumeroItem() {
    let error = '';
    if (Number.isNaN(numeroItem) || numeroItem < 0) {
      error = 'Este campo é obrigatório';
    } else if (numeroItem > itens.length) {
      error = 'Não existem itens com esse código!';
    }
    return error;
  }

  function validateSenha() {
    let error = '';
    if (!password) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function getErros() {
    const errosOld = [
      '',
      '',
    ];
    errosOld[0] = validateNumeroItem();
    errosOld[1] = validateSenha();

    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle 
          id="form-dialog-title" 
          style={{ 
            color: 'white', 
            backgroundColor:  modoOffline ? SECONDARY_COLOR_OFFLINE : SECONDARY_COLOR, 
            textAlign: 'center' 
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <IntegerInput
            name={inputs[0].nome}
            ref={refs.current[0]}
            handleEnter={() => handleNextInput(0)}
            label={inputs[0].label}
            handleKey={handleKey}
            value={Number.isNaN(numeroItem) ? '' : String(numeroItem)}
            onChange={(value) => setNumeroItem(parseInt(value.target.value, 10))}
            fullWidth
            autoFocus
            error={erros[0] !== ''}
            helperText={erros[0]}
          />
          <TextInput
            name={inputs[1].nome}
            ref={refs.current[1]}
            type={showPassword ? 'text' : 'password'}
            label={inputs[1].label}
            value={password}
            onChange={handleChange}
            fullWidth
            handleKey={handleKey}
            handleEnter={() => handleNextInput(1)}
            error={erros[1] !== ''}
            helperText={erros[1]}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
              )}
            labelWidth={70}
          />
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={handleCloseDialog}
            color="secondary"
            variant="contained"
            tabindex="-1"
          >
            {`Cancelar (${CANCELAR_VENDA_ACTION})`}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSalvarDialog}
            disabled={erroExistente}
            tabindex="-1"
          >
            {`Confirmar (${FECHAR_VENDA_ACTION})`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoNota;
