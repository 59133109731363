import React, {
  useRef, forwardRef, useImperativeHandle, useState, useEffect,
} from 'react';

import {
  Paper, Box, Button,
} from '@material-ui/core';
import moment from 'moment';

import {
  AutoCompleteLocal,
  DateInput,
  PrecoInput,
} from '../../../../components/Inputs';

import {
  DialogoContadorCedulas
} from '../../../../components';

import { A_VISTA, formatMoeda } from '../../../../utils';

const SidebarInputs = forwardRef(({
  handleNewItem,
  handleKey = () => {},
  disabledSubmit = false,
  resto,
  validateParcela,
  hasPagamentoDinheiro,
  parcelas,
  margin = 'normal',
  cliente,
  descontoDisponivelCliente,
  descontoUsadoCliente,
  tipoPagamentoCartaoCreditoPermissao,
  tipoPagamentoCartaoDebitoPermissao,
  tipoPagamentoDevolucaoPermissao,
  tipoPagamentoDinheiroPermissao,
  tipoPagamentoPixDistanciaPermissao,
  tipoPagamentoPixQrcodePermissao,
  tipoPagamentoVoucherPermissao,
  tipoPagamentoCreditoFidelidadePermissao,
  meioPagamentosOptions,
  totalAVistaObrigatorio,
  totalAVistaCalculado,
  contasPixADistanciaOptions
}, ref) => {
  const [tipoPagamento, setTipoPagamento] = useState(null);
  const [valor, setValor] = useState(0);
  const [dataPagamento, setDataPagamento] = useState(moment().format('YYYY-MM-DD'));
  const [valorRecebido, setValorRecebido] = useState(0);
  const [erros, setErros] = useState(['', '', '', '']);
  const [meioPagamentosUsaveisOptions, setMeioPagamentosUsaveisOptions] = useState([]);
  const [contaPixADistancia, setContaPixADistancia] = useState(null);
  const refs = useRef([]);
  const refButton = useRef(null);
  const refCacheTipoPagamento = useRef(null);

  const refDialogoContadorCedulas = useRef(null)

  const isFidelidade = cliente ? cliente.is_fidelidade : false;

  function updateOptionsUsaveis(meioPagamentosOptions) {
    const usaveisNew = [];
    let pagamentoDinheiro = null;
    let pagamentoCartao = null;
    for (let i = 0; i < meioPagamentosOptions.length; i += 1) {
      if (meioPagamentosOptions[i].id === 3 && tipoPagamentoCartaoCreditoPermissao) {
        pagamentoCartao = meioPagamentosOptions[i];
      }
      if (meioPagamentosOptions[i].id === 11 && tipoPagamentoCreditoFidelidadePermissao) {
        if (isFidelidade) {
          usaveisNew.push(meioPagamentosOptions[i]);
        }
      } else if (meioPagamentosOptions[i].id === 1 && tipoPagamentoDinheiroPermissao) {
        pagamentoDinheiro = meioPagamentosOptions[i];
        if (hasPagamentoDinheiro !== true) {
          usaveisNew.push(meioPagamentosOptions[i]);
        }
      } else if (
        (meioPagamentosOptions[i].id === 3 && tipoPagamentoCartaoCreditoPermissao)
          || (meioPagamentosOptions[i].id === 4 && tipoPagamentoCartaoDebitoPermissao)
          || (meioPagamentosOptions[i].id === 5 && tipoPagamentoPixQrcodePermissao)
          || (meioPagamentosOptions[i].id === 7 && tipoPagamentoPixQrcodePermissao)
          || (meioPagamentosOptions[i].id === 8 && tipoPagamentoVoucherPermissao)
          || (meioPagamentosOptions[i].id === 9 && tipoPagamentoDevolucaoPermissao)
          || (meioPagamentosOptions[i].id === 10 && tipoPagamentoPixDistanciaPermissao)
      ) {
        usaveisNew.push(meioPagamentosOptions[i]);
      }
    }
    setMeioPagamentosUsaveisOptions(usaveisNew);
    if (hasPagamentoDinheiro === true) {
      if (pagamentoCartao) {
        setTipoPagamento(pagamentoCartao);
      } else if (usaveisNew.length > 0) {
        setTipoPagamento(usaveisNew[0]);
      } else {
        setTipoPagamento(null);
      }
    } else if (pagamentoDinheiro) {
      setTipoPagamento(pagamentoDinheiro);
    } else if (usaveisNew.length > 0) {
      setTipoPagamento(usaveisNew[0]);
    } else {
      setTipoPagamento(null);
    }
  }

  function updateOptionsUsaveis2(meioPagamentosOptions) {
    const usaveisNew = [];
    for (let i = 0; i < meioPagamentosOptions.length; i += 1) {
      if (meioPagamentosOptions[i].id === 11 && tipoPagamentoCreditoFidelidadePermissao) {
        if (isFidelidade) {
          usaveisNew.push(meioPagamentosOptions[i]);
        }
      } else if (meioPagamentosOptions[i].id === 1 && tipoPagamentoDinheiroPermissao) {
        if (hasPagamentoDinheiro !== true) {
          usaveisNew.push(meioPagamentosOptions[i]);
        }
      } else if (
        (meioPagamentosOptions[i].id === 3 && tipoPagamentoCartaoCreditoPermissao)
          || (meioPagamentosOptions[i].id === 4 && tipoPagamentoCartaoDebitoPermissao)
          || (meioPagamentosOptions[i].id === 5 && tipoPagamentoPixQrcodePermissao)
          || (meioPagamentosOptions[i].id === 7 && tipoPagamentoPixQrcodePermissao)
          || (meioPagamentosOptions[i].id === 8 && tipoPagamentoVoucherPermissao)
          || (meioPagamentosOptions[i].id === 9 && tipoPagamentoDevolucaoPermissao)
          || (meioPagamentosOptions[i].id === 10 && tipoPagamentoPixDistanciaPermissao)
      ) {
        usaveisNew.push(meioPagamentosOptions[i]);
      }
    }
    setMeioPagamentosUsaveisOptions(usaveisNew);
  }

  useEffect(() => {
    updateOptionsUsaveis(meioPagamentosOptions);
  }, []);

  useEffect(() => {
    updateOptionsUsaveis(meioPagamentosOptions);
  }, [parcelas]);

  useEffect(() => {
    updateOptionsUsaveis2(meioPagamentosOptions);
  }, [cliente]);

  function hideValorRecebido(tipoPagamento) {
    return (!tipoPagamento
      ? true : tipoPagamento.dinheiro_vivo && tipoPagamento.modalidade === A_VISTA);
  }

  function disabledDataPagamento() {
    return (!tipoPagamento ? true : tipoPagamento.modalidade === A_VISTA);
  }

  function validateTipoPagamento() {
    let error = '';
    if (!tipoPagamento) {
      error = 'Este campo é obrigatório';
    } else {
      const { status, message } = validateParcela(tipoPagamento.id);
      if (status === false) {
        error = message;
      }
    }
    return error;
  }

  function validateValor() {
    let error = '';
    if (valor <= 0 || Number.isNaN(valor)) {
      error = 'Este campo é obrigatório';
    } else if (tipoPagamento) {
      if(tipoPagamento.id === 1) {
        const valorInteiro = Math.round(valor * 100)
        if(valorInteiro % 5 !== 0) {
          error = 'Este valor deve ser um múltiplo de 5 centavos!!';
        }
      } else {
        if (tipoPagamento.id === 11) {
          if (valor > descontoDisponivelCliente - descontoUsadoCliente) {
            error = 'Este valor é maior que o desconto disponível para este cliente!';
          } else if (valor % 100 !== 0) {
            error = 'Este valor deve ser múltplo de 100!';
          }
        } else {
          if(!(tipoPagamento.id === 1 || tipoPagamento.id === 10)) {
            if(totalAVistaObrigatorio > 0) {
              if(totalAVistaCalculado < totalAVistaObrigatorio) {
                const totalPossivel = resto - (totalAVistaObrigatorio - totalAVistaCalculado)
                if(valor > totalPossivel) {
                  error = `Este valor não pode ser maior que ${formatMoeda(totalPossivel)}, pois ainda falta ${formatMoeda(totalAVistaObrigatorio - totalAVistaCalculado)} de pagamento à vista!`;
                }
              }
            }
          }
        }
      }
    }

    return error;
  }

  function validateDataPagamento() {
    let error = '';
    if (dataPagamento === null || dataPagamento === '') error = 'Data Inválida!';
    return error;
  }

  function validateValorRecebido() {
    let error = '';
    if (tipoPagamento) {
      if (tipoPagamento.dinheiro_vivo && tipoPagamento.modalidade === A_VISTA) {
        if (valorRecebido <= 0 || Number.isNaN(valorRecebido)) {
          error = 'Este campo é obrigatório';
        } else if (valorRecebido < valor) error = 'O valor recebido não poder ser menor que o valor da parcela!';
      }
    }
    return error;
  }

  function validateContaPixADistancia() {
    let error = '';
    if(tipoPagamento) {
      if(tipoPagamento.id === 10) {
        if (!contaPixADistancia) {
          error = 'Este campo é obrigatório';
        }
      }
    } else {
      error = 'O meio de pagamento é obrigatório';
    }
    return error;
  }

  function getErros() {
    const errosOld = erros.slice();
    errosOld[0] = validateTipoPagamento();
    errosOld[1] = validateValorRecebido();
    errosOld[2] = validateDataPagamento();
    errosOld[3] = validateValor();
    errosOld[4] = validateContaPixADistancia();
    return errosOld;
  }

  useEffect(() => {
    setErros(getErros());
  }, [tipoPagamento, valor, dataPagamento, valorRecebido, contaPixADistancia]);

  const inputs = [
    {
      nome: 'tipo_pagamento',
      defaultValue: null,
      label: 'Meio de pagamento', // 0
      resource: 'tipospagamento',
      nested: [],
      filters: { ativo: true },
    },
    {
      nome: 'valor_recebido',
      defaultValue: 0, // 1
      label: 'Valor bruto recebido do cliente',
    },
    {
      nome: 'data_pagamento',
      defaultValue: moment().format('YYYY-MM-DD'), // 2
      label: 'Data de Pagamento',
    },
    {
      nome: 'valor',
      defaultValue: 0, // 3
      label: 'Valor real da parcela',
    },
    {
      nome: 'conta_pix_a_distancia',
      defaultValue: null,
      label: 'Conta', // 4
      resource: 'tipospagamento',
      nested: [],
      filters: { },
    },
  ];

  refs.current = inputs.map(
    (ref, index) => refs.current[index] = React.createRef(),
  );

  function resetValues() {
    setErros(['', '', '', '']);
    setValor(inputs[1].defaultValue);
    setDataPagamento(inputs[2].defaultValue);
    setValorRecebido(inputs[3].defaultValue);
    setContaPixADistancia(inputs[4].defaultValue)
  }

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  function buildData(data) {
    const ano = parseInt(data.substring(0, 4), 10);
    const mes = parseInt(data.substring(5, 7), 10) - 1;
    const dia = parseInt(data.substring(8, 10), 10);

    const dataFormatada = new Date();
    dataFormatada.setFullYear(ano);
    dataFormatada.setDate(dia);
    dataFormatada.setMonth(mes);
    dataFormatada.setDate(dia);

    return dataFormatada;
  }

  function handleSubmit() {
    if (!hasError()) {
      if (refs.current[0].current) {
        refs.current[0].current.focus();
        refs.current[0].current.select();
      }
      handleNewItem({
        tipo_pagamento: tipoPagamento,
        valor: parseFloat(valor),
        data_pagamento: buildData(dataPagamento),
        valor_recebido: parseFloat(valorRecebido),
        troco: parseFloat(valorRecebido) - parseFloat(valor),
        conta_id: tipoPagamento.id === 10 ? (contaPixADistancia ? contaPixADistancia.id : null) : null,
        conta: tipoPagamento.id === 10 ? (contaPixADistancia ? contaPixADistancia : null) : null,
      });
      resetValues();
    }
  }

  useImperativeHandle(ref, () => ({
    focus(index = 0) {
      if (refs.current[index].current) {
        refs.current[index].current.focus();
        refs.current[index].current.select();
      }
    },
    submit() {
      handleSubmit();
    },
  }));

  const  visibleInputContaPixADistancia = tipoPagamento ? tipoPagamento.id === 10 : false

  function getRefNextInput(index) {
    let position = -1;
    switch (index) {
      case 0:
        //position = 1;
        /* if (hideValorRecebido()) {
          //position = 1;
          if(tipoPagamento ? tipoPagamento.id === 1 : false) {
            if(refDialogoContadorCedulas.current) {
              refDialogoContadorCedulas.current.handleOpen()
            }
          }
        } else  */

        

        if (hideValorRecebido(refCacheTipoPagamento.current ? refCacheTipoPagamento.current : tipoPagamento)) {
          if(refCacheTipoPagamento.current) {
            const tipoPagamento = refCacheTipoPagamento.current
            if(tipoPagamento ? tipoPagamento.id === 1 : false) {
              if(refDialogoContadorCedulas.current) {
                refDialogoContadorCedulas.current.handleOpen()
              }
            }
          } else {
            if(tipoPagamento ? tipoPagamento.id === 1 : false) {
              if(refDialogoContadorCedulas.current) {
                refDialogoContadorCedulas.current.handleOpen()
              }
            }
          }
        } else
        if(!disabledDataPagamento()) position = 2;
        else  position = 3;
        break;
      case 1:
        /*if (!disabledDataPagamento()) position = 2;
        else if (hideValorRecebido()) position = 3;
        if (visibleInputContaPixADistancia) position = 4;
        if (Number.isNaN(parseFloat(valor))) setValor(resto);*/
        if (!disabledDataPagamento()) position = 2;
        else position = 3;
        break;
      case 2:
        //if (hideValorRecebido()) position = 3;
        position = 3;
        break;
      case 3:
        if (Number.isNaN(parseFloat(valor))) setValor(resto);
        else if (visibleInputContaPixADistancia) position = 4;
      default:
        break;
    }
    return position;
  }

  function handleNextInput(index) {
    const position = getRefNextInput(index);
    if (position === -1) {
      if (refButton.current && !disabledSubmit) {
        refButton.current.click();
      }
    } else if (refs.current[position].current) {
      refs.current[position].current.focus();
      refs.current[position].current.select();
    }
  }

  function handleKey2(keyCode, keyName) {
   /*  if(keyName === 'F9') {
      if(tipoPagamento ? tipoPagamento.id === 1 : false) {
        if(refDialogoContadorCedulas.current) {
          refDialogoContadorCedulas.current.handleOpen()
        }
      }
    } else {
      handleKey(keyCode, keyName)
    } */
    handleKey(keyCode, keyName)
  }

  return (
    <Paper elevation={3} style={{ opacity: '0.75' }}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        padding="15px"
        overflow="auto"
      >
        <AutoCompleteLocal
          name={inputs[0].nome}
          ref={refs.current[0]}
          handleEnter={() => {
            handleNextInput(0)
          }}
          label={inputs[0].label}
          handleKey={handleKey2}
          value={tipoPagamento}
          onChangeLocal={(value) => {
            if(value) {
              refCacheTipoPagamento.current = value
            } else {
              refCacheTipoPagamento.current = null
            }
           
            setTipoPagamento(value)
          }}
          error={erros[0] !== ''}
          helperText={erros[0]}
          campo="nome"
          options={meioPagamentosUsaveisOptions}
          margin={margin}
        />

        { hideValorRecebido(tipoPagamento) && (
        <PrecoInput
          name={inputs[1].nome}
          ref={refs.current[1]}
          handleEnter={() => handleNextInput(1)}
          label={inputs[1].label}
          handleKey={handleKey2}
          value={Number.isNaN(valorRecebido) ? '' : String(valorRecebido)}
          onChange={(value) => setValorRecebido(parseFloat(value.target.value))}
          error={erros[1] !== ''}
          helperText={erros[1]}
          margin={margin}
          disabled
        />
        )}
        <DateInput
          name={inputs[2].nome}
          ref={refs.current[2]}
          handleEnter={() => handleNextInput(2)}
          label={inputs[2].label}
          handleKey={handleKey2}
          value={dataPagamento}
          onChange={(value) => setDataPagamento(value.target.value)}
          error={erros[2] !== ''}
          helperText={erros[2]}
          disabled={disabledDataPagamento()}
          margin={margin}
        />
        <PrecoInput
          name={inputs[3].nome}
          ref={refs.current[3]}
          handleEnter={() => handleNextInput(3)}
          label={inputs[3].label}
          handleKey={handleKey2}
          value={Number.isNaN(valor) ? '' : String(valor)}
          onChange={(value) => setValor(parseFloat(value.target.value))}
          error={erros[3] !== ''}
          helperText={erros[3]}
          margin={margin}
        />
        {(!tipoPagamento
          ? true : tipoPagamento.dinheiro_vivo && tipoPagamento.modalidade === A_VISTA) && (
          <PrecoInput
            name="troco"
            handleEnter={() => {}}
            label="Troco"
            handleKey={handleKey2}
            value={Number.isNaN(valorRecebido - valor) ? '' : String(valorRecebido - valor)}
            onChange={() => {}}
            disabled
            margin={margin}
          />
        )}
         {visibleInputContaPixADistancia ?
          <AutoCompleteLocal
            name={inputs[4].nome}
            ref={refs.current[4]}
            handleEnter={() => handleNextInput(4)}
            label={inputs[4].label}
            handleKey={handleKey2}
            value={contaPixADistancia}
            onChangeLocal={(value) => setContaPixADistancia(value)}
            error={erros[4] !== ''}
            helperText={erros[4]}
            campo="nome"
            options={contasPixADistanciaOptions}
            margin={margin}
          /> : null
        }
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          ref={refButton}
          disabled={hasError()}
        >
          Adicionar à lista
        </Button>
      </Box>
      <DialogoContadorCedulas 
        ref={refDialogoContadorCedulas}
        handleClose2={() => {
          if (refs.current[3].current) {
            refs.current[3].current.focus();
            refs.current[3].current.select();
          }
        }}
        handleClose={() => {}}
        handleSalvar={(total, cedulas) => {
          
          setValorRecebido(total)
        }}
        getTitle={(total) => `Valor Recebido em Dinheiro (${formatMoeda(total)})`}
      />
    </Paper>
  );
});

export default SidebarInputs;
