import { setModoOffline } from '../services/config'

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_SALDO_CAIXA':
      return {
        ...state,
        saldoCaixa: action.saldoCaixa,
      };
    case 'UPDATE_MENSAGEM_TELA_INICIAL_FC':
      return {
        ...state,
        mensagemInicialFc: action.mensagemInicialFc,
      };
    case 'UPDATE_SALDO_INDICADOR_RETIRADA':
      return {
        ...state,
        saldoIndicadorRetirada: action.saldoIndicadorRetirada,
      };
    case 'UPDATE_MEIOS_PAGAMENTO':
      return {
        ...state,
        meiosPagamento: action.meiosPagamento,
      };
    case 'UPDATE_LIMITES_CPF_NOTA':
      return {
        ...state,
        limiteInferiorNotaCpf: action.limiteInferiorNotaCpf,
        limiteSuperiorNotaCpf: action.limiteSuperiorNotaCpf,
      };
    case 'UPDATE_VIDEOS_TUTORIAIS':
      return {
        ...state,
        videosTutoriais: action.videosTutoriais,
      };
    case 'UPDATE_CONTAS_PIX_A_DISTANCIA':
      return {
        ...state,
        contasPixADistancia: action.contasPixADistancia,
      };
    case 'CHANGE_MODO_OFFLINE':
      setModoOffline(action.modoOffline)
      return {
        ...state,
        modoOffline: action.modoOffline,
      };
    case 'UPDATE_IS_BUSCOU_DADOS_CONFIG':
      return {
        ...state,
        isBuscouDadosConfig: action.isBuscouDadosConfig,
      };
    case 'UPDATE_DADOS_CONFIG':
      return {
        ...state,
        saldoIndicadorRetirada: action.saldoIndicadorRetirada,
        isBuscouDadosConfig: action.isBuscouDadosConfig,
        limiteInferiorNotaCpf: action.limiteInferiorNotaCpf,
        limiteSuperiorNotaCpf: action.limiteSuperiorNotaCpf,
        mensagemInicialFc: action.mensagemInicialFc,
        valorMaximoPesoNoFc: action.valorMaximoPesoNoFc,
      };
    case 'UPDATE_PERMISSOES':
      return {
        ...state,
        criarVendaPermissao: action.criarVendaPermissao,
        consultarPrecosPermissao: action.consultarPrecosPermissao,
        utilizarTrocoPermissao: action.utilizarTrocoPermissao,
        utilizarTrocoAberturaCaixaPermissao: action.utilizarTrocoAberturaCaixaPermissao,
        criarPedidoPermissao: action.criarPedidoPermissao,
        criarRetiradaPermissao: action.criarRetiradaPermissao,
        carregarPedidoPermissao: action.carregarPedidoPermissao,
        criarClientePermissao: action.criarClientePermissao,
        editarClientePermissao: action.editarClientePermissao,
        tipoPagamentoDinheiroPermissao: action.tipoPagamentoDinheiroPermissao,
        tipoPagamentoCartaoCreditoPermissao: action.tipoPagamentoCartaoCreditoPermissao,
        tipoPagamentoCartaoDebitoPermissao: action.tipoPagamentoCartaoDebitoPermissao,
        tipoPagamentoPixQrcodePermissao: action.tipoPagamentoPixQrcodePermissao,
        tipoPagamentoVoucherPermissao: action.tipoPagamentoVoucherPermissao,
        tipoPagamentoDevolucaoPermissao: action.tipoPagamentoDevolucaoPermissao,
        tipoPagamentoPixDistanciaPermissao: action.tipoPagamentoPixDistanciaPermissao,
        tipoPagamentoCreditoFidelidadePermissao: action.tipoPagamentoCreditoFidelidadePermissao,
        pesquisaPorNomes: action.pesquisaPorNomes,
        tipoCaixa: action.tipoCaixa,
        nomeCaixa: action.nomeCaixa,
        isBuscouDadosPermissoes: true,
        pesquisaPorCodigoDeBalancaPermissao: action.pesquisaPorCodigoDeBalancaPermissao,
        conferenciaGrandesPesagensPermissao: action.conferenciaGrandesPesagensPermissao,
      };
    default:
      return state;
  }
};

export default reducer;
