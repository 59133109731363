import {
  useEffect,
  useRef,
  useState,
} from 'react';

import { useSnackbar } from 'notistack';

import { api, imprimirNotaVenda, setVendaCancelada, getUserId } from '../services';
import {
  A_VISTA,
  A_PRAZO,
  TIPO_OFERTA_PRODUTO_LIBERACAO,
  round10,
  toStringItemEnviar
} from '../utils';

const idTiposPagamentoRepetidos = [3, 4, 5, 7, 8, 9, 10, 11];

// 1 - dinheiro
// 2 - fiado
// 3 - crédito
// 4 - débito
// 5 - pix qrcode
// 6 - Boleto
// 7 - pix - banco do brasil
// 8 - voucher
// 9 - devolucao
// 10 - pix a distancia
// 11 - credito fidelidade

function useParcelasVenda(
  parcelas,
  itens,
  cliente,
  subtotal,
  sessionId,
  updateParcela,
  irParaTelaInit,
  isEmPartes,
  itensExcluidos,
  cpf,
  uuid,
  modoOffline
) {
  const { enqueueSnackbar } = useSnackbar();
  const [podeFecharVenda, setPodeFecharVenda] = useState(true);
  const controle = useRef({ status: true })

  function getTotalAVistaObrigatorio() {
    let total = 0
    for(let i = 0; i < itens.length; i++) {
      const item = itens[i]
      if(item.tipo_oferta === TIPO_OFERTA_PRODUTO_LIBERACAO) {
        if(!item.is_margem_cartao) {
          total += item.total
        }
      }
    }
    return total
  }

  function getTotalAVistaCalculado() {
    let total = 0
    for(let i = 0; i < parcelas.length; i++) {
      const parcela = parcelas[i]
      if(parcela.tipo_pagamento.id === 1 || parcela.tipo_pagamento.id === 10) {
        total += parcela.valor
      }
    }
    return total
  }

  const totalAVistaObrigatorio = getTotalAVistaObrigatorio()
  const totalAVistaCalculado = getTotalAVistaCalculado()

  function parcelaInclusa(id) {
    for (let i = 0; i < idTiposPagamentoRepetidos.length; i += 1) {
      if (id === idTiposPagamentoRepetidos[i]) return true;
    }
    return false;
  }

  function getProcuraPagamentoDinheiro() {
    for (let i = 0; i < parcelas.length; i += 1) {
      if (parcelas[i].tipo_pagamento.id === 1) return true;
    }
    return false;
  }

  function getProcuraPagamentoNaoPadrao() {
    return false;
  }

  function getPodeEncerrarTransacao(resto) {
    if (resto >= -2 && resto <= 2) return true;
    return false;
  }

  function getValorRestante() {
    let soma = 0;
    for (let i = 0; i < parcelas.length; i += 1) {
      soma += parcelas[i].valor;
    }
    return subtotal - soma;
  }

  function getTotalParcelas() {
    let soma = 0;
    for (let i = 0; i < parcelas.length; i += 1) {
      soma += parcelas[i].valor;
    }
    return soma;
  }

  function getDescontoUsado() {
    let soma = 0;
    for (let i = 0; i < parcelas.length; i += 1) {
      if (parcelas[i].tipo_pagamento.id === 11) {
        soma += parcelas[i].valor;
      }
    }
    return soma;
  }

  function getDescontosDisponiveisCliente() {
    if (cliente) {
      return parseInt(cliente.score_nao_usado / 100, 10) * 100;
    }
    return 0;
  }

  const resto = getValorRestante();
  const podeEncerrarTransacao = getPodeEncerrarTransacao(resto);
  const hasPagamentoDinheiro = getProcuraPagamentoDinheiro();
  const hasPagamentoNaoPadrao = getProcuraPagamentoNaoPadrao();
  const totalParcelas = getTotalParcelas();
  const descontoUsadoCliente = getDescontoUsado();
  const descontoDisponivelCliente = getDescontosDisponiveisCliente();

  function hasPixADistancia() {
    return parcelas.some(({ tipo_pagamento }) => tipo_pagamento.id === 10)
  }

  function getMessagesError() {
    const errors = [];
    if (cliente === null) {
      errors.push({
        message: 'Campo cliente não foi preenchido!',
        type: 'warning',
      });
    }
    if (parcelas.length <= 0) {
      errors.push({
        message: 'É necessário ao menos um registro de parcela!',
        type: 'warning',
      });
    }
    if(hasPixADistancia()) {
      if(cliente === null) {
        ///
      } else {
        if(cliente.id === 1) {
          errors.push({
            message: 'É necessário um cliente com cadastro para o pix à distância!',
            type: 'warning',
          });
        }
      }
    }
    const erroDistribuicao = hasPagamentoDinheiro
      ? !podeEncerrarTransacao : (resto < -0.05 || resto > 0.05);
    if (erroDistribuicao) {
      errors.push({
        message: 'O valor total da venda não foi integralmente distribuído nas parcelas!',
        type: 'warning',
      });
    }

    const erroToleravel = hasPagamentoDinheiro ? 2 : 0

    if (round10(totalAVistaCalculado, -2) < round10(totalAVistaObrigatorio - erroToleravel, -2)) {
      errors.push({
        message: 'O total obrigatório à vista (dinheiro ou pix a distância) não foi respeitado!',
        type: 'warning',
      });
    }
    return errors;
  }

  useEffect(() => {
    if (getMessagesError().length === 0) {
      enqueueSnackbar(
        'Agora você pode finalizar a Venda!!!',
        {
          variant: 'success',
        },
      );
    }
  }, [parcelas]);

  function montarObjVenda() {
    const listaItens = [];
    const listaItensExcluidos = [];
    const listaParcelas = [];

    for (let i = 0; i < itens.length; i += 1) {
      listaItens.push({
        peso: itens[i].peso,
        unidades: itens[i].unidades,
        preco_venda: itens[i].unitario,
        preco_compra: itens[i].produto.precoCompraMedio,
        produto_id: itens[i].produto.id,
        idIndicadorChange: itens[i].idIndicadorChange,
        unidades_caixa: itens[i].unidadesCaixa,
        preco_venda_caixa: itens[i].unitarioCaixa,
        peso_caixa: itens[i].pesoCaixa,
        unidades_caixa_aparente: itens[i].unidadesCaixaAparente,
        nivel_caixaria: itens[i].nivelCaixaria,
        cgp: itens[i].cgp,
        grupo_oferta_preco_produto_id: itens[i].grupo_oferta_preco_produto_id ? itens[i].grupo_oferta_preco_produto_id : null,
        tipo_oferta: itens[i].tipo_oferta ? itens[i].tipo_oferta : null,
        is_margem_cartao: itens[i].is_margem_cartao === true ? true : false,
        dadosCgp: itens[i].dadosCgp,
        ...toStringItemEnviar(itens[i])
      });
    }

    for (let i = 0; i < itensExcluidos.length; i += 1) {
      listaItensExcluidos.push({
        peso: itensExcluidos[i].peso,
        unidades: itensExcluidos[i].unidades,
        preco_venda: itensExcluidos[i].unitario,
        preco_compra: itensExcluidos[i].produto.precoCompraMedio,
        produto_id: itensExcluidos[i].produto.id,
        idIndicadorChange: itensExcluidos[i].idIndicadorChange,
        unidades_caixa: itensExcluidos[i].unidadesCaixa,
        preco_venda_caixa: itensExcluidos[i].unitarioCaixa,
        peso_caixa: itensExcluidos[i].pesoCaixa,
        unidades_caixa_aparente: itensExcluidos[i].unidadesCaixaAparente,
        nivel_caixaria: itensExcluidos[i].nivelCaixaria,
      });
    }

    for (let i = 0; i < parcelas.length; i += 1) {
      listaParcelas.push({
        tipo_pagamento_id: parcelas[i].tipo_pagamento.id,
        data_pagamento: parcelas[i].data_pagamento,
        valor: parcelas[i].valor,
        data_pagamento_real:
                parcelas[i].tipo_pagamento.modalidade === A_VISTA ? new Date() : null,
        valor_recebido:
          (parcelas[i].tipo_pagamento.dinheiro_vivo
            && parcelas[i].tipo_pagamento.modalidade === A_VISTA
          )
            ? parcelas[i].valor_recebido : 0,
        idIndicadorChange: parcelas[i].idIndicadorChange,
        autorizador_id: parcelas[i].autorizador_id ? parcelas[i].autorizador_id : null,
        conta_id: parcelas[i].conta_id ? parcelas[i].conta_id : null,
        credito_devolucao_venda_id: parcelas[i].credito_devolucao_venda_id ? parcelas[i].credito_devolucao_venda_id : null,
      });
    }

    return {
      listaItens,
      listaItensExcluidos,
      listaParcelas,
      data: new Date(),
      cliente_id: cliente.id,
      sessao_id: sessionId,
      devolucao: false,
      is_em_partes: isEmPartes,
      cpf,
      uuid,
      subtotal,
      userId: getUserId()
    };
  }

  async function submitVenda() {
    try {
      const objVenda = montarObjVenda();
      const response = await api.post('/vendas_frente_caixa_novo', {
        ...objVenda,
      });
      const data = response.data
      if(data.status) {
        setVendaCancelada(null)
        return {
          id: data.data.id,
          message: ''
        }
      }
      throw data.message
    } catch (e) {
      return {
        id: -1,
        message: 'ERRO NO REGISTRO DA NOTA'
      };
    }
  }

  async function handleFinalizarVenda() {
    const errors = getMessagesError();
    if (errors.length <= 0 && controle.current.status) {
      controle.current.status = false
      setPodeFecharVenda(false);
      const responseVenda = await submitVenda();
      if (responseVenda.id > 0) {
        const codigo = await imprimirNotaVenda(responseVenda.id, modoOffline);
        if (codigo < 0) {
          enqueueSnackbar(
            'Erro ao imprimir a nota!',
            {
              variant: 'error',
            },
          );
        }
        irParaTelaInit();
      } else {
        controle.current.status = true
        setPodeFecharVenda(true);
        enqueueSnackbar(
          responseVenda.message,
          {
            variant: 'error',
          },
        );
      }
    } else {
      for (let i = 0; i < errors.length; i += 1) {
        enqueueSnackbar(
          errors[i].message,
          {
            variant: errors[i].type,
          },
        );
      }
    }
  }

  function handleNewItem({
    valor,
    tipo_pagamento,
    data_pagamento,
    troco,
    valor_recebido,
    conta_id,
    conta
  }, autorizador_id) {
    if (
      tipo_pagamento.modalidade === A_VISTA
      || (tipo_pagamento.modalidade === A_PRAZO
        && cliente.nome
        && cliente.cpf
        && cliente.telefone
        && tipo_pagamento.boleto_gerencianet)
      || (tipo_pagamento.modalidade === A_PRAZO && !tipo_pagamento.boleto_gerencianet)
    ) {
      updateParcela({
        data_pagamento,
        tipo_pagamento,
        valor,
        uidd: `${tipo_pagamento.nome}${parcelas.length}`,
        troco,
        valor_recebido,
        idIndicadorChange: -1,
        autorizador_id,
        conta_id,
        conta
      });
    } else {
      enqueueSnackbar(
        'Este cliente não possui dados suficientes para o cadastro de boletos na plataforma Gerencianet!',
        {
          variant: 'warning',
        },
      );
    }
  }

  function getPodeUsarParcela(id) {
    const idInclusoRepetidos = parcelaInclusa(id);

    if (idInclusoRepetidos) {
      return {
        status: true,
        message: '',
      };
    }
    if (id === 1) {
      if (hasPagamentoDinheiro) {
        return {
          status: false,
          message: 'Já existem uma parcela em dinheiro registrada!',
        };
      }
      return {
        status: true,
        message: '',
      };
    }
    if (hasPagamentoNaoPadrao) {
      return {
        status: false,
        message: 'Já existem uma parcela á prazo registrada!',
      };
    }
    return {
      status: true,
      message: '',
    };
  }

  

  return {
    resto,
    podeEncerrarTransacao,
    hasPagamentoDinheiro,
    totalParcelas,
    handleFinalizarVenda,
    handleNewItem,
    enqueueSnackbar,
    getPodeUsarParcela,
    hasPagamentoNaoPadrao,
    podeFecharVenda,
    descontoUsadoCliente,
    descontoDisponivelCliente,
    totalAVistaObrigatorio,
    totalAVistaCalculado
  };
}

export default useParcelasVenda;
