import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useContext
} from 'react';

import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, createStyles, withStyles
} from '@material-ui/core';

import {
  ATALHO_VOLTAR,
  ATALHO_AVANCAR,
  SECONDARY_COLOR,
  formatMoeda,
  SECONDARY_COLOR_OFFLINE
} from '../../../../../utils';
import InputBase from '@material-ui/core/InputBase';
import { api } from '../../../../../services'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { AppContext } from '../../../../../app/context';
import { useSnackbar } from 'notistack';

const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const ESC_ACTION = 'Escape';
const CONSULTOR_PRECOS_ACTION = 'F3';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => createStyles({
  action: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.secondary.main
  },
  disabledButton: {
    backgroundColor: 'red'
  }
}));

const DialogoCpfNota = forwardRef(({ cliente, handleCarregarCreditoDevolucoes= () => {}, openConsultorPrecos= () => {} }, ref) => {
  const [open, setOpen] = useState(false);
  const [dados, setDados] = useState([])
  const [carregando, setCarregando] = useState(false);
  const classes = useStyles();
  const { app: { modoOffline } } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  function handleClose() {
    setOpen(false);
  }

  useImperativeHandle(ref, () => ({
    handleOpen() {
      buscarDados()
    },
  }));

  async function buscarDados() {
    setOpen(true);
    setCarregando(true)
    try {
      const devolucoes = await getCredito(cliente.id)
      setDados(devolucoes.data)
      setCarregando(false)
    } catch(e) {
      setDados([])
      setCarregando(false)
      setOpen(false)
      enqueueSnackbar(
        'INTERNET: ERRO AO BUSCAR OS CRÉDITOS',
        {
          variant: 'error',
        },
      );
    }
  }

  async function handleSalvar() {
    if(!carregando) {
      try {
        setOpen(false);
        const dados2 = await marcarCreditosDevolucoesComoUsados(dados)
        handleCarregarCreditoDevolucoes(dados2.data)
        setDados([])
      } catch(e) {
        setOpen(false);
        enqueueSnackbar(
          'INTERNET: ERRO AO MARCAR OS CRÉDITOS COMO USADOS',
          {
            variant: 'error',
          },
        );
      }
    }
  }

  function handleActions(action, name) {
    if(!carregando) {
      switch (name) {
        case FECHAR_VENDA_ACTION:
          handleSalvar()
          break;
        case CANCELAR_VENDA_ACTION:
          handleClose();
          break;
        case ESC_ACTION:
          handleClose();
          break;
        case CONSULTOR_PRECOS_ACTION:
          openConsultorPrecos();
          break;
        default:
          break;
      }
    }
  }

  async function getCredito(id) {
    const response = await api.get(`/get_devolucao_venda_credito_cliente/${id}`)
    return response.data
  }

  async function marcarCreditosDevolucoesComoUsados(devolucoes) {
    const ids = devolucoes.map(item => item.id)
    const response = await api.put(`/marcar_credito_devolucao_como_usado`, {
      creditos: ids
    })
    return response.data
  }

  const content = `Deseja carregar o crédito de devolução de venda disponível para o cliente ${cliente ? cliente.nome : ''}`

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title" 
          style={{ color: 'white', backgroundColor: modoOffline ? SECONDARY_COLOR_OFFLINE : SECONDARY_COLOR, textAlign: 'center' }}
        >
          {`Carregar Parcela de Crédito de Devolução para o cliente - ${cliente ? cliente.nome : ''}`}
        </DialogTitle>
        <DialogContent 
          style={{
            padding: '20px 10px',
          }}
        >
          <InputBase
            label=""
            value={content}
            onChange={() => {}}
            fullWidth
            onKeyDown={(e) => {
              if (e.keyCode === 13) {}
              else handleActions(e.keyCode, e.key);
            }}
            multiline
            autoFocus
            style={{
              margin: 0,
              padding: '10px',
              border: 'none',
              outline: 'none'
            }}
          />
          <h2 style={{ margin: '30px 0', textAlign: 'center' }}>Créditos de Devolução Disponíveis</h2>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>Venda Referente</StyledTableCell>
                  <StyledTableCell>Data da Venda</StyledTableCell>
                  <StyledTableCell >
                    Valor
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dados.map((row) => {
                  return (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        { row.numeroVenda}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        { row.dataVenda}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        { formatMoeda(row.valor)}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
          <DialogActions className={classes.action}>
            <Button 
              onClick={() =>  handleClose()} 
              color="secondary"
              variant="contained"
              tabindex="-1"
            >
              {`Cancelar (${CANCELAR_VENDA_ACTION})`}
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={()=> handleSalvar()}
              tabindex="-1"
              disabled={carregando}
            >
              {`Confirmar (${FECHAR_VENDA_ACTION})`}
            </Button>
          </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCpfNota;
