import React, { useReducer, useEffect, useRef } from 'react';
import {
  Route, Switch, useRouteMatch, useHistory,
} from 'react-router-dom';

import {
  getListAllAPI,
  getSessionId,
  api
} from '../../services';
import {
  CLIENTE_DEFAULT,
} from '../../utils';
import InicioFrenteCaixaVenda from './_SubPages/Itens';
import FinalizarFrenteCaixaVenda from './_SubPages/Parcelas';
import { VendaContext } from './context';
import reducer from './reducer';
import {
  Button
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

const tempoPing = 5 * 1000

const CreateVenda = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [venda, dispatch] = useReducer(reducer, {
    cliente: null,
    itens: [],
    itensExcluidos: [],
    parcelas: [],
    idEdit: -1,
    isEditPrice: false,
    tipoVenda: 0,
    isEmPartes: false,
    uuid: null
  });
  const { path } = useRouteMatch();
  const refCont = useRef(0)

  async function getUuid() {
    try {
      const data = await api.get('/generate-uuid');
      dispatch({ type: 'UPDATE_UUID', uuid: data.data.uuid });
    } catch(e) {
      enqueueSnackbar(
        'INTERNET: ERRO AO GERAR UUID',
        {
          variant: 'error',
        },
      );
    }
  }

  useEffect(() => {
    getUuid();
  }, []);

  async function  getDefaults() {
    try {
      const dataClientes = await getListAllAPI(
        'clientes',
        ['id', 'asc'],
        { nome: CLIENTE_DEFAULT },
        ['cidade.estado', 'categoria'],
      );

      dispatch({ type: 'UPDATE_CLIENTE', cliente: dataClientes.data[0] });
    } catch(e) {
      enqueueSnackbar(
        'INTERNET: ERRO AO BUSCAR O CLIENTE ANÔNIMO',
        {
          variant: 'error',
        },
      );
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      getDados()
      refCont.current = 1
    }, tempoPing);
    return () => clearInterval(timer);
  });

  async function getDados() {
    if(refCont.current) {
      if(!venda.cliente) {
        await getDefaults()
      }
      if(!venda.uuid) {
        await getUuid()
      }
    }
  }

  useEffect(() => {
    getDefaults();
  }, []);

  function irParaTelaInit() {
    history.push('/');
  }

  function irParaTelaFinalizarVenda() {
    history.push('/vendas/finalizarvenda');
  }

  function irParaTelaFrentedeCaixa() {
    history.push('/vendas/frentedecaixa');
  }

  return (
    <>
      { venda.cliente && venda.uuid ? 
        <VendaContext.Provider value={{ venda, dispatch }}>
          <Switch>
            <Route
              path={`${path}/frentedecaixa`}
              render={(props) => (
                <InicioFrenteCaixaVenda
                  irParaTelaInit={irParaTelaInit}
                  irParaTelaFinalizarVenda={irParaTelaFinalizarVenda}
                />
              )}
            />
            <Route
              path={`${path}/finalizarvenda`}
              render={(props) => (
                <FinalizarFrenteCaixaVenda
                  irParaTelaInit={irParaTelaInit}
                  irParaTelaFrentedeCaixa={irParaTelaFrentedeCaixa}
                  getSessionId={() => getSessionId()}
                />
              )}
            />
          </Switch>
        </VendaContext.Provider>
        : 
        <div style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'red',
          fontSize: '40px',
          flexDirection: 'column',
          gap: '10px'
        }}>
          Inicializando a Venda...
          <Button 
            onClick={irParaTelaInit} 
            color="secondary" 
            variant="contained"
            autoFocus
          >
            {`Voltar Para a Tela Inicial`}
          </Button>
        </div>
      }
    </>
  );
};

export default CreateVenda;
