import React, { forwardRef } from 'react';

import { TextField } from '@material-ui/core';

export const TextInput = forwardRef(
  (
    {
      handleEnter = () => {},
      handleKey = () => {},
      variant = 'outlined',
      ...props
    },
    ref,
  ) => (
    <TextField
      margin="normal"
      variant={variant}
      {...props}
      inputRef={ref}
      onKeyDown={(e) => {
        if (e.keyCode === 13) handleEnter();
        else handleKey(e.keyCode, e.key);
      }}
    />
  ),
);
