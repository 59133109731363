export function formatCPF(cpf) {
  return cpf ? `${cpf.substring(0, 3)}.${cpf.substring(3, 6)}.${cpf.substring(
    6,
    9,
  )}-${cpf.substring(9)}` : '';
}

export function formatCNPJ(cnpj) {
  return cnpj ? `${cnpj.substring(0, 2)}.${cnpj.substring(2, 5)}.${cnpj.substring(
    5,
    8,
  )}/${cnpj.substring(8, 12)}-${cnpj.substring(12)}` : '';
}

export function formatCEP(cep) {
  return cep ? `${cep.substring(0, 5)}-${cep.substring(5)}` : '';
}

export function formatTelefone(telefone) {
  return telefone ? `(${telefone.substring(0, 2)}) ${telefone.substring(
    2,
    7,
  )}-${telefone.substring(7)}` : '';
}

export function formatMoeda(valor) {
  return valor !== null && valor !== undefined ? valor.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  }) : '';
}

export function formatPeso(valor) {
  return valor !== null && valor !== undefined ? valor.toLocaleString('de-DE', {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  }) : '';
}

export function formatUnidades(valor) {
  return valor !== null && valor !== undefined ? valor.toLocaleString('de-DE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }) : '';
}

export function formatPreco(valor) {
  return valor !== null && valor !== undefined ? valor.toLocaleString('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }) : '';
}

export function formatIE(cnpj) {
  return cnpj ? `${cnpj.substring(0, 3)}.${cnpj.substring(3, 6)}.${cnpj.substring(
    6,
    9,
  )}` : '';
}
