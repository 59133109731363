import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
  useContext
} from 'react';

import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, createStyles,
} from '@material-ui/core';

import {
  CpfInput,
  DialogoConfirmacao
} from '../../../../../components';
import {
  ATALHO_VOLTAR,
  ATALHO_AVANCAR,
  SECONDARY_COLOR,
  validaCpf,
  SECONDARY_COLOR_OFFLINE
} from '../../../../../utils';
import InputBase from '@material-ui/core/InputBase';

import { AppContext } from '../../../../../app/context';

const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const ESC_ACTION = 'Escape';
const CONSULTOR_PRECOS_ACTION = 'F3';

const useStyles = makeStyles((theme) => createStyles({
  action: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.secondary.main
  },
  disabledButton: {
    backgroundColor: 'red'
  }
}));

const DialogoCpfNota = forwardRef(({ handleProsseguir = () => {}, openConsultorPrecos= () => {} }, ref) => {
  const [open, setOpen] = useState(false);
  const [cpf, setCpf] = useState('');
  const [modo, setModo] = useState(false);
  const classes = useStyles();
  const { app: { modoOffline } } = useContext(AppContext);


  function handleClose() {
    setOpen(false);
  }

  useImperativeHandle(ref, () => ({
    handleOpen(value) {
      setCpf('');
      setOpen(true);
      setModo(value)
    },
  }));

  function handleSalvar() {
    setOpen(false);
    handleProsseguir(cpf)
  }

  function handleSalvar2() {
    setOpen(false);
    handleProsseguir(null)
  }

  function handleActions(action) {
    switch (action) {
      case FECHAR_VENDA_ACTION:
        handleSalvar()
        break;
      case CANCELAR_VENDA_ACTION:
        handleClose();
        break;
      case ESC_ACTION:
        handleClose();
        break;
      case CONSULTOR_PRECOS_ACTION:
        openConsultorPrecos();
        break;
      default:
        break;
    }
  }

  function handleKey(keyCode, keyName) {
    handleActions(keyName);
  }

  function validateCpf() {
    let error = '';
    if (cpf) {
      if (cpf.length < 11) error = 'O CPF deve ter 11 dígitos';
      else {
        error = validaCpf(cpf)
      }
    } else {
      error = 'Este campo é obrigatório'
    }
    return error
  }

  const erroCpf = validateCpf();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title" 
          style={{ color: 'white', backgroundColor: modoOffline ? SECONDARY_COLOR_OFFLINE : SECONDARY_COLOR, textAlign: 'center' }}
        >
          CPF na Nota
        </DialogTitle>
        <DialogContent 
          style={{
            padding: '20px 10px',
          }}
        >
          {!modo ? 
            <CpfInput
              name='cpf'
              handleEnter={() => {
                if(erroCpf === '') {
                  handleSalvar()
                }
              }}
              label='CPF'
              handleKey={handleKey}
              value={cpf}
              onChange={(value) => setCpf(value.target.value)}
              error={erroCpf !== ''}
              helperText={erroCpf}
              fullWidth
              autoCompleteState="new-password"
              autoFocus
            /> : 
            <InputBase
              label=""
              value={'Deseja incluir o CPF nessa nota?'}
              onChange={() => {}}
              fullWidth
              onKeyDown={(e) => {
                if (e.keyCode === 13) setModo(false);
                else {
                  switch (e.key) {
                    case FECHAR_VENDA_ACTION:
                      setModo(false)
                      break;
                    case CANCELAR_VENDA_ACTION:
                      handleSalvar2()
                      break;
                    case ESC_ACTION:
                      handleSalvar2()
                      break;
                    case CONSULTOR_PRECOS_ACTION:
                      openConsultorPrecos();
                      break;
                    default:
                      break;
                  }
                }
              }}
              multiline
              autoFocus
              style={{
                margin: 0,
                padding: '10px',
                border: 'none',
                outline: 'none'
              }}
            />
          }
        </DialogContent>
        {!modo ? 
          <DialogActions className={classes.action}>
            <Button 
              onClick={handleClose} 
              color="secondary"
              variant="contained"
              tabindex="-1"
            >
              {`Fechar (${CANCELAR_VENDA_ACTION})`}
            </Button>
            <Button
              color="secondary"
              variant="contained"
                onClick={handleSalvar}
                tabindex="-1"
                disabled={erroCpf !== ''}
              >
              {`Prosseguir (${FECHAR_VENDA_ACTION})`}
            </Button>
          </DialogActions> : 
          <DialogActions className={classes.action}>
            <Button 
              onClick={() =>  handleSalvar2()} 
              color="secondary"
              variant="contained"
              tabindex="-1"
            >
              {`Não (${CANCELAR_VENDA_ACTION})`}
            </Button>
            <Button
              color="secondary"
              variant="contained"
                onClick={()=> setModo(false)}
                tabindex="-1"
              >
              {`Sim (${FECHAR_VENDA_ACTION})`}
            </Button>
          </DialogActions>
        }
      </Dialog>
    </div>
  );
});

export default DialogoCpfNota;
