export const CLIENTE_DEFAULT = 'Anônimo';

export const ID_CLIENTE_DEFAULT = 1;

export const A_VISTA = 'Á Vista';
export const A_PRAZO = 'Á Prazo';

export const POR_PESO_VARIAVEL = 'Por Peso Variável';
export const POR_PESO_FIXO = 'Por Peso Fixo';
export const POR_UNIDADE = 'Por Unidade';
export const POR_PESO_VARIAVEL_SEM_UNIDADE = 'Por Peso Variável Sem Unidade';

export const TIPO_PAGAMENTO_DINHEIRO = 'Dinheiro';
export const TIPO_PAGAMENTO_FIADO = 'Fiado';
export const TIPO_PAGAMENTO_CARTAO_CREDITO = 'Cartão de Crédito';
export const TIPO_PAGAMENTO_CARTAO_DEBITO = 'Cartão de Débito';
export const TIPO_PAGAMENTO_TED_PIX = 'TED/PIX';
export const TIPO_PAGAMENTO_BOLETO_GERENCIANET = 'Boleto';

export const ID_TIPO_PAGAMENTO_DINHEIRO = 1;
export const ID_TIPO_PAGAMENTO_FIADO = 2;
export const ID_TIPO_PAGAMENTO_CARTAO_CREDITO = 3;
export const ID_TIPO_PAGAMENTO_CARTAO_DEBITO = 4;
export const ID_TIPO_PAGAMENTO_TED_PIX = 5;
export const ID_TIPO_PAGAMENTO_BOLETO_GERENCIANET = 6;

export const ATALHO_VOLTAR = 'F4';
export const ATALHO_AVANCAR = 'F8';
export const ATALHO_OPEN_MENU = 'insert';

export const PRIMARY_COLOR = process.env.REACT_APP_PRIMARY_COLOR;
export const SECONDARY_COLOR = process.env.REACT_APP_SECONDARY_COLOR;

export const URL_SERVIDOR_IMPRESSORA = process.env.REACT_APP_HOST_IMPRESSORA;

export const MALOTE_MALOTE_OPTION = 'malote_malote';
export const MALOTE_SUBMALOTE_OPTION = 'malote_submalote';
export const MALOTE_SANGRIA_OPTION = 'malote_sangria';
export const MALOTE_TROCO_OPTION = 'malote_troco';

export const STATUS_ATIVO = 'ativo';
export const STATUS_INATIVO = 'inativo';
export const STATUS_DELETADO = 'deletado';

export const TIPO_OFERTA_PRODUTO_JORNAL = 'tipo_oferta_produto_jornal';
export const TIPO_OFERTA_PRODUTO_ZERAR_ESTOQUE =
  'tipo_oferta_produto_zerar_estoque';
export const TIPO_OFERTA_PRODUTO_LIBERACAO = 'tipo_oferta_produto_liberacao';

export const TIPO_UNIDADE_OFERTA_KG = 'tipo_unidade_oferta_kg';
export const TIPO_UNIDADE_OFERTA_UN = 'tipo_unidade_oferta_un';
export const TIPO_UNIDADE_OFERTA_CAIXA = 'tipo_unidade_oferta_caixa';
export const TIPO_UNIDADE_OFERTA_CAIXETA = 'tipo_unidade_oferta_caixeta';
export const TIPO_UNIDADE_OFERTA_CAIXA_MAE = 'tipo_unidade_oferta_caixa_mae';

export const APP_VERSION = "63.0.0"
export const APP_VERSION_FUNCIONALIDADES = ""

export const ZERO_APROXIMADO = 0.0099

export const IMAGEM_BACKGROUND = process.env.REACT_APP_IMAGEM_BACKGROUND;

export const TIPO_PRECIFICACAO_NORMAL = 'tipo_precificacao_normal';
export const TIPO_PRECIFICACAO_COMPRA = 'tipo_precificacao_compra';
export const TIPO_PRECIFICACAO_LIBERACAO_PRECO =
  'tipo_precificacao_liberacao_preco';
export const TIPO_PRECIFICACAO_OFERTA = 'tipo_precificacao_oferta';
export const TIPO_PRECIFICACAO_ZERAR_ESTOQUE =
  'tipo_precificacao_zerar_estoque';

export const PRIMARY_COLOR_OFFLINE = process.env.REACT_APP_PRIMARY_COLOR_OFFLINE;
export const SECONDARY_COLOR_OFFLINE = process.env.REACT_APP_SECONDARY_COLOR_OFFLINE;
