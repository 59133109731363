import {
  POR_PESO_FIXO,
  POR_UNIDADE,
  TIPO_UNIDADE_OFERTA_KG,
  TIPO_UNIDADE_OFERTA_UN,
  TIPO_UNIDADE_OFERTA_CAIXA,
  TIPO_UNIDADE_OFERTA_CAIXETA,
  TIPO_UNIDADE_OFERTA_CAIXA_MAE,
  TIPO_OFERTA_PRODUTO_LIBERACAO,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  POR_PESO_VARIAVEL,
  ZERO_APROXIMADO,
} from './constants';
import { formatPeso } from './formats';
import { trunc10 } from './arredondamentos'

function fatorPesoMedio(produto) {
  if (produto.unidade.tipo === POR_PESO_FIXO) {
    return produto.peso_medio;
  }
  return 1;
}

function valorIntervalo(oferta, qtde) {
  let preco = oferta.valor;
  let continuar = true;
  for (let i = 0; i < oferta.escalonadas.length && continuar; i++) {
    if (oferta.escalonadas[i].quantidade <= qtde) {
      preco = oferta.escalonadas[i].valor;
    } else {
      continuar = false;
    }
  }
  return preco;
}

function valorIntervalo2(oferta, qtde, precoVenda) {
  let preco =
    oferta.tipo !== TIPO_OFERTA_PRODUTO_LIBERACAO ? oferta.valor : precoVenda;
  let continuar = true;
  for (let i = 0; i < oferta.escalonadas.length && continuar; i++) {
    if (oferta.escalonadas[i].quantidade <= qtde) {
      preco = oferta.escalonadas[i].valor;
    } else {
      continuar = false;
    }
  }
  return preco;
}

function getQuantidadeBasicas(produto, unidades, peso) {
  if (
    produto.unidade.tipo === POR_UNIDADE ||
    produto.unidade.tipo === POR_PESO_FIXO
  ) {
    if (produto.nivelCaixa) {
      switch (produto.nivelCaixa) {
        case 1:
          return { qtde: unidades * produto.unidades_caixa_nivel_1, tipo: 0 };
        case 2:
          return {
            qtde:
              unidades *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2,
            tipo: 0,
          };
        case 3:
          return {
            qtde:
              unidades *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3,
            tipo: 0,
          };
        default:
          return { qtde: unidades, tipo: 0 };
      }
    } else {
      return { qtde: unidades, tipo: 0 };
    }
  }
  if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
    if (produto.nivelCaixa) {
      switch (produto.nivelCaixa) {
        case 1:
          return { qtde: unidades * produto.peso_caixa_nivel_1, tipo: 1 };
        case 2:
          return {
            qtde:
              unidades *
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2,
            tipo: 1,
          };
        case 3:
          return {
            qtde:
              unidades *
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3,
            tipo: 1,
          };
        default:
          return { qtde: peso, tipo: 1 };
      }
    } else {
      return { qtde: peso, tipo: 1 };
    }
  }
  return { qtde: 0, tipo: 1 };
}

function getQuantidade(tipoUnidade, produto, unidades, peso) {
  const { qtde, tipo } = getQuantidadeBasicas(produto, unidades, peso);
  switch (tipoUnidade) {
    case TIPO_UNIDADE_OFERTA_KG:
      return qtde;
    case TIPO_UNIDADE_OFERTA_UN:
      return qtde;
    case TIPO_UNIDADE_OFERTA_CAIXA: {
      const qtdeCaixa = getQuantidadeEfetiva(produto, 1);
      return qtde / qtdeCaixa;
    }
    case TIPO_UNIDADE_OFERTA_CAIXETA: {
      const qtdeCaixa = getQuantidadeEfetiva(produto, 2);
      return qtde / qtdeCaixa;
    }
    case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
      const qtdeCaixa = getQuantidadeEfetiva(produto, 3);
      return qtde / qtdeCaixa;
    }
    default:
      return qtde;
  }
}

function getPrecoUnitarioVerdadeiro(produto, unidades, peso) {
  /*if (produto.ofertasProdutos.length > 0) {
    let preco = produto.preco_venda;
    for (let i = 0; i < produto.ofertasProdutos.length; i++) {
      const oferta = produto.ofertasProdutos[i];
      const qtde = getQuantidade(oferta.tipo_unidade, produto, unidades, peso);
      const preco2 = valorIntervalo2(oferta, qtde, produto.preco_venda);
      if (preco2 < preco) {
        preco = preco2;
      }
    }
    return preco;
  }
  return produto.preco_venda;*/

  /*if (produto.ofertasProdutos.length > 0) {
    let preco = produto.preco_venda;
    for (let i = 0; i < produto.ofertasProdutos.length; i++) {
      const oferta = produto.ofertasProdutos[i];
      if (oferta.tipo !== TIPO_OFERTA_PRODUTO_LIBERACAO) {
        const qtde = getQuantidade(
          oferta.tipo_unidade,
          produto,
          unidades,
          peso,
        );
        const preco2 = valorIntervalo(oferta, qtde);
        if (preco2 < preco) {
          preco = preco2;
        }
      }
    }
    return preco;
  }
  return produto.preco_venda;*/

  if (produto.ofertasProdutos.length > 0) {
    let preco = produto.preco_venda;
    for (let i = 0; i < produto.ofertasProdutos.length; i++) {
      const oferta = produto.ofertasProdutos[i];
      if (oferta.tipo !== TIPO_OFERTA_PRODUTO_LIBERACAO) {
        const precoOferta = oferta.valor;
        if (preco > precoOferta) {
          preco = precoOferta;
        }
      }
    }
    return preco;
  }
  return produto.preco_venda;
}

function getPrecoUnitarioVerdadeiroCaixa(produto, unidades, peso) {
  const preco = getPrecoUnitarioVerdadeiro(produto, unidades, peso);
  if (produto.nivelCaixa) {
    switch (produto.nivelCaixa) {
      case 1: {
        const qtde = getQuantidadeEfetiva(produto, 1);
        return preco * qtde * fatorPesoMedio(produto);
      }
      case 2: {
        const qtde = getQuantidadeEfetiva(produto, 2);
        return preco * qtde * fatorPesoMedio(produto);
      }
      case 3: {
        const qtde = getQuantidadeEfetiva(produto, 3);
        return preco * qtde * fatorPesoMedio(produto);
      }
      default:
        return preco;
    }
  } else {
    return preco;
  }
}

function getPrecoUnitarioVerdadeiro2(produto, unidades, peso) {
  /*if (produto.ofertasProdutos.length > 0) {
    let preco = produto.preco_venda;
    for (let i = 0; i < produto.ofertasProdutos.length; i++) {
      const oferta = produto.ofertasProdutos[i];
      if (oferta.tipo !== TIPO_OFERTA_PRODUTO_LIBERACAO) {
        const qtde = getQuantidade(
          oferta.tipo_unidade,
          produto,
          unidades,
          peso,
        );
        const preco2 = valorIntervalo(oferta, qtde);
        if (preco2 < preco) {
          preco = preco2;
        }
      }
    }
    return preco;
  }
  return produto.preco_venda;*/

  if (produto.ofertasProdutos.length > 0) {
    let preco = produto.preco_venda;
    for (let i = 0; i < produto.ofertasProdutos.length; i++) {
      const oferta = produto.ofertasProdutos[i];
      if (oferta.tipo !== TIPO_OFERTA_PRODUTO_LIBERACAO) {
        const precoOferta = oferta.valor;
        if (preco > precoOferta) {
          preco = precoOferta;
        }
      }
    }
    return preco;
  }
  return produto.preco_venda;
}

function getPrecoUnitarioVerdadeiroCaixa2(produto, unidades, peso) {
  const preco = getPrecoUnitarioVerdadeiro2(produto, unidades, peso);
  if (produto.nivelCaixa) {
    switch (produto.nivelCaixa) {
      case 1: {
        const qtde = getQuantidadeEfetiva(produto, 1);
        return preco * qtde * fatorPesoMedio(produto);
      }
      case 2: {
        const qtde = getQuantidadeEfetiva(produto, 2);
        return preco * qtde * fatorPesoMedio(produto);
      }
      case 3: {
        const qtde = getQuantidadeEfetiva(produto, 3);
        return preco * qtde * fatorPesoMedio(produto);
      }
      default:
        return preco;
    }
  } else {
    return preco;
  }
}

function getQuantidadeCaixaria(produto, nivel) {
  switch (produto.unidade.tipo) {
    case POR_UNIDADE: {
      switch (nivel) {
        case 1:
          return { unidades: produto.unidades_caixa_nivel_1, peso: 0, fatorPesoMedio: 1, isPesoFixo: false, isInUnidade: true };
        case 2:
          return {
            unidades:
              produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2,
            peso: 0,
            fatorPesoMedio: 1, isPesoFixo: false,
            isInUnidade: true
          };
        case 3:
          return {
            unidades:
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3,
            peso: 0,
            fatorPesoMedio: 1, isPesoFixo: false ,
            isInUnidade: true
          };
        default:
          return { unidades: 0, peso: 0, fatorPesoMedio: 1, isPesoFixo: false, isInUnidade: true };
      }
    }
    case POR_PESO_VARIAVEL_SEM_UNIDADE: {
      switch (nivel) {
        case 1:
          return { unidades: 0, peso: produto.peso_caixa_nivel_1, fatorPesoMedio: 1, isPesoFixo: false, isInUnidade: false  };
        case 2:
          return {
            unidades: produto.unidades_caixa_nivel_2,
            peso: produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2, 
            fatorPesoMedio: 1, isPesoFixo: false , isInUnidade: false
          };
        case 3:
          return {
            unidades:
              produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_3,
            peso:
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3,
            fatorPesoMedio: 1, isPesoFixo: false , isInUnidade: false
          };
        default:
          return { unidades: 0, peso: 0, fatorPesoMedio: 1, isPesoFixo: false, isInUnidade: false  };
      }
    }
    case POR_PESO_VARIAVEL: {
      return { unidades: 0, peso: 0, fatorPesoMedio: 1, isPesoFixo: false, isInUnidade: false  };
    }
    case POR_PESO_FIXO: {
      switch (nivel) {
        case 1:
          return { unidades: produto.unidades_caixa_nivel_1, peso: 0, fatorPesoMedio: produto.peso_medio, isPesoFixo: true, isInUnidade: true  };
        case 2:
          return {
            unidades:
              produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2,
            peso: 0,
            fatorPesoMedio: produto.peso_medio,
            isPesoFixo: true, isInUnidade: true
          };
        case 3:
          return {
            unidades:
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3,
            peso: 0,
            fatorPesoMedio: produto.peso_medio
            , isPesoFixo: true, isInUnidade: true
          };
        default:
          return { unidades: 0, peso: 0, fatorPesoMedio: 1, isPesoFixo: true, isInUnidade: true };
      }
    }
    default:
      return { unidades: 0, peso: 0, fatorPesoMedio: 1, isPesoFixo: false, isInUnidade: true };
  }
}

function getQuantidadeEfetiva(produto, nivel) {
  const { unidades, peso } = getQuantidadeCaixaria(produto, nivel);
  if (peso > 0) {
    return peso;
  }
  if (unidades > 0) {
    return unidades;
  }
  return 0;
}

function getQuantidadeCaixariaLabel(produto, nivel) {
  const { unidades, peso } = getQuantidadeCaixaria(produto, nivel);
  if (peso > ZERO_APROXIMADO) {
    return `${formatPeso(peso)} Kg`;
  }
  if (unidades > 0) {
    return `${unidades} Un`;
  }
  return '-';
}

function getNivelCaixaQtde(produto) {
  if (produto.unidades_caixa_nivel_3 > 0) {
    return 3;
  }

  if (produto.unidades_caixa_nivel_2 > 0) {
    return 2;
  }

  if (
    produto.unidades_caixa_nivel_1 > 0 ||
    produto.peso_caixa_nivel_1 > ZERO_APROXIMADO
  ) {
    return 1;
  }

  return 0;
}

function getLabelTipoUnidade(tipo) {
  switch (tipo) {
    case TIPO_UNIDADE_OFERTA_KG:
      return 'Kg';
    case TIPO_UNIDADE_OFERTA_UN:
      return 'Un';
    case TIPO_UNIDADE_OFERTA_CAIXA:
      return 'Caixa Nível 1';
    case TIPO_UNIDADE_OFERTA_CAIXETA:
      return 'Caixa Nível 2';
    case TIPO_UNIDADE_OFERTA_CAIXA_MAE:
      return 'Caixa Nível 3';
    default:
      return '';
  }
}

function getNivelCaixaCodigo(produto, codigo) {
  if (produto) {
    switch (codigo) {
      case produto.codigo_caixa_nivel_1:
        return 1;
      case produto.codigo_caixa_nivel_2:
        return 2;
      case produto.codigo_caixa_nivel_3:
        return 3;
      default:
        return 0;
    }
  } else {
    return 0;
  }
}

function getTotalCaixaTipoOferta(produto, precoUnitario, tipoUnidadeOferta) {
  if (produto) {
    switch (produto.unidade.tipo) {
      case POR_UNIDADE: {
        switch (tipoUnidadeOferta) {
          case TIPO_UNIDADE_OFERTA_CAIXA: {
            return produto.unidades_caixa_nivel_1 * precoUnitario;
          }
          case TIPO_UNIDADE_OFERTA_CAIXETA: {
            return (
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              precoUnitario
            );
          }
          case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
            return (
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3 *
              precoUnitario
            );
          }
          default: {
            return 0;
          }
        }
      }
      case POR_PESO_FIXO: {
        switch (tipoUnidadeOferta) {
          case TIPO_UNIDADE_OFERTA_CAIXA: {
            return (
              produto.unidades_caixa_nivel_1 *
              precoUnitario *
              produto.peso_medio
            );
          }
          case TIPO_UNIDADE_OFERTA_CAIXETA: {
            return (
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              precoUnitario *
              produto.peso_medio
            );
          }
          case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
            return (
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3 *
              precoUnitario *
              produto.peso_medio
            );
          }
          default: {
            return 0;
          }
        }
      }
      case POR_PESO_VARIAVEL_SEM_UNIDADE: {
        switch (tipoUnidadeOferta) {
          case TIPO_UNIDADE_OFERTA_CAIXA: {
            return produto.peso_caixa_nivel_1 * precoUnitario;
          }
          case TIPO_UNIDADE_OFERTA_CAIXETA: {
            return (
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              precoUnitario
            );
          }
          case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
            return (
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3 *
              precoUnitario
            );
          }
          default: {
            return 0;
          }
        }
      }
      default: {
        return 0;
      }
    }
  }
  return 0;
}

function getLabelTipoUnidadeCaixaria(tipoUnidadeOferta, produto) {
  if (produto) {
    if (tipoUnidadeOferta) {
      switch (tipoUnidadeOferta) {
        case TIPO_UNIDADE_OFERTA_CAIXA:
          return `Caixa Nível 1 (${getQuantidadeCaixariaLabel(produto, 1)})`;
        case TIPO_UNIDADE_OFERTA_CAIXETA:
          return `Caixa Nível 2 (${getQuantidadeCaixariaLabel(produto, 2)})`;
        case TIPO_UNIDADE_OFERTA_CAIXA_MAE:
          return `Caixa Nível 3 (${getQuantidadeCaixariaLabel(produto, 3)})`;
        default:
          return '';
      }
    }
    return '';
  }
  return '';
}

function getOptionsTipoUnidadeOferta(produto) {
  if (produto) {
    const tipo = produto.unidade.tipo;
    switch (tipo) {
      case POR_UNIDADE: {
        const nivel = getNivelCaixaQtde(produto);
        switch (nivel) {
          case 0:
            return [
              {
                label: 'Un',
                value: TIPO_UNIDADE_OFERTA_UN,
              },
            ];
          case 1:
            return [
              {
                label: 'Un',
                value: TIPO_UNIDADE_OFERTA_UN,
              },
              {
                label: 'Caixa Nível 1',
                value: TIPO_UNIDADE_OFERTA_CAIXA,
              },
            ];
          case 2:
            return [
              {
                label: 'Un',
                value: TIPO_UNIDADE_OFERTA_UN,
              },
              {
                label: 'Caixa Nível 1',
                value: TIPO_UNIDADE_OFERTA_CAIXA,
              },
              {
                label: 'Caixa Nível 2',
                value: TIPO_UNIDADE_OFERTA_CAIXETA,
              },
            ];
          case 3:
            return [
              {
                label: 'Un',
                value: TIPO_UNIDADE_OFERTA_UN,
              },
              {
                label: 'Caixa Nível 1',
                value: TIPO_UNIDADE_OFERTA_CAIXA,
              },
              {
                label: 'Caixa Nível 2',
                value: TIPO_UNIDADE_OFERTA_CAIXETA,
              },
              {
                label: 'Caixa Nível 3',
                value: TIPO_UNIDADE_OFERTA_CAIXA_MAE,
              },
            ];
          default:
            return [];
        }
      }
      case POR_PESO_FIXO: {
        const nivel = getNivelCaixaQtde(produto);
        switch (nivel) {
          case 0:
            return [
              {
                label: 'Un',
                value: TIPO_UNIDADE_OFERTA_UN,
              },
            ];
          case 1:
            return [
              {
                label: 'Un',
                value: TIPO_UNIDADE_OFERTA_UN,
              },
              {
                label: 'Caixa Nível 1',
                value: TIPO_UNIDADE_OFERTA_CAIXA,
              },
            ];
          case 2:
            return [
              {
                label: 'Un',
                value: TIPO_UNIDADE_OFERTA_UN,
              },
              {
                label: 'Caixa Nível 1',
                value: TIPO_UNIDADE_OFERTA_CAIXA,
              },
              {
                label: 'Caixa Nível 2',
                value: TIPO_UNIDADE_OFERTA_CAIXETA,
              },
            ];
          case 3:
            return [
              {
                label: 'Un',
                value: TIPO_UNIDADE_OFERTA_UN,
              },
              {
                label: 'Caixa Nível 1',
                value: TIPO_UNIDADE_OFERTA_CAIXA,
              },
              {
                label: 'Caixa Nível 2',
                value: TIPO_UNIDADE_OFERTA_CAIXETA,
              },
              {
                label: 'Caixa Nível 3',
                value: TIPO_UNIDADE_OFERTA_CAIXA_MAE,
              },
            ];
          default:
            return [];
        }
      }
      case POR_PESO_VARIAVEL:
        return [
          {
            label: 'Kg',
            value: TIPO_UNIDADE_OFERTA_KG,
          },
        ];
      case POR_PESO_VARIAVEL_SEM_UNIDADE: {
        const nivel = getNivelCaixaQtde(produto);
        switch (nivel) {
          case 0:
            return [
              {
                label: 'Kg',
                value: TIPO_UNIDADE_OFERTA_KG,
              },
            ];
          case 1:
            return [
              {
                label: 'Kg',
                value: TIPO_UNIDADE_OFERTA_KG,
              },
              {
                label: 'Caixa Nível 1',
                value: TIPO_UNIDADE_OFERTA_CAIXA,
              },
            ];
          case 2:
            return [
              {
                label: 'Kg',
                value: TIPO_UNIDADE_OFERTA_KG,
              },
              {
                label: 'Caixa Nível 1',
                value: TIPO_UNIDADE_OFERTA_CAIXA,
              },
              {
                label: 'Caixa Nível 2',
                value: TIPO_UNIDADE_OFERTA_CAIXETA,
              },
            ];
          case 3:
            return [
              {
                label: 'Kg',
                value: TIPO_UNIDADE_OFERTA_KG,
              },
              {
                label: 'Caixa Nível 1',
                value: TIPO_UNIDADE_OFERTA_CAIXA,
              },
              {
                label: 'Caixa Nível 2',
                value: TIPO_UNIDADE_OFERTA_CAIXETA,
              },
              {
                label: 'Caixa Nível 3',
                value: TIPO_UNIDADE_OFERTA_CAIXA_MAE,
              },
            ];
          default:
            return [];
        }
      }
      default:
        return [];
    }
  }
  return [];
}

function getLabelUnidade(tipo) {
  if (tipo === POR_UNIDADE) {
    return 'un';
  }
  return 'kg';
}

function getPrecos(produto) {
  if (produto.nivelCaixa) {
    switch (produto.nivelCaixa) {
      case 1:
        return [
          {
            preco: getPrecoUnitarioVerdadeiroCaixa2({...produto, nivelCaixa: 1}, 0, 0),
            sufixo: 'Cx1',
            qtde: getQuantidadeCaixariaLabel(produto, 1),
          },
        ];
      case 2:
        return [
          {
            preco: getPrecoUnitarioVerdadeiroCaixa2({...produto, nivelCaixa: 1}, 0, 0),
            sufixo: 'Cx1',
            qtde: getQuantidadeCaixariaLabel(produto, 1),
          },
          {
            preco: getPrecoUnitarioVerdadeiroCaixa2({...produto, nivelCaixa: 2}, 0, 0),
            sufixo: 'Cx2',
            qtde: getQuantidadeCaixariaLabel(produto, 2),
          },
        ];
      case 3:
        return [
          {
            preco: getPrecoUnitarioVerdadeiroCaixa2({...produto, nivelCaixa: 1}, 0, 0),
            sufixo: 'Cx1',
            qtde: getQuantidadeCaixariaLabel(produto, 1),
          },
          {
            preco: getPrecoUnitarioVerdadeiroCaixa2({...produto, nivelCaixa: 2}, 0, 0),
            sufixo: 'Cx2',
            qtde: getQuantidadeCaixariaLabel(produto, 2),
          },
          {
            preco: getPrecoUnitarioVerdadeiroCaixa2({...produto, nivelCaixa: 3}, 0, 0),
            sufixo: 'Cx3',
            qtde: getQuantidadeCaixariaLabel(produto, 3),
          },
        ];
      default:
        return [];
    }
  } else {
    return [];
  }
}

function getNomeProduto(produto, mensagem2 = 'NOVO PEDIDO', mensagem1 = 'Nenhum Produto') {
  if (produto) {
    if (produto.nivelCaixa) {
      switch (produto.nivelCaixa) {
        case 1:
          return `${produto.nome} - cx(${getQuantidadeCaixariaLabel(produto, 1)}) - Nível 1`;
        case 2:
          return `${produto.nome} - cx(${getQuantidadeCaixariaLabel(produto, 2)}) - Nível 2`;
        case 3:
          return `${produto.nome} - cx(${getQuantidadeCaixariaLabel(produto, 3)}) - Nível 3`;
        default:
          return mensagem1;
      }
    } else {
      return produto.nome;
    }
  } else {
    return mensagem2;
  }
}

function analisaCodigo(codigo) {
  if (codigo.length === 13 && codigo.charAt(0) === '2') {
    return {
      tipo: 0,
      data: {
        codigo_interno: codigo.substring(1, 7),
        peso:
            (parseInt(codigo.substring(7, 9), 10)
            + parseInt(codigo.substring(9, 12), 10) / 1000),
        digitoVerificador: parseInt(codigo.substring(12, 13), 10),
      },
    };
  }
  if (codigo.length === 18 && codigo.charAt(0) === '2') {
    return {
      tipo: 0,
      data: {
        codigo_interno: codigo.substring(1, 7),
        peso:
            (parseInt(codigo.substring(7, 12), 10)
            + parseInt(codigo.substring(12, 17), 10) / 100000),
        digitoVerificador: parseInt(codigo.substring(17, 18), 10),
      },
    };
  }
  if (codigo.length === 16) {
    if(codigo.substring(0, 6) === '0CFCGP') {
      return {
        tipo: 'cgp',
        data: {
          codigo_interno: parseInt(codigo.substring(6),10),
          peso: 0,
          digitoVerificador: 0,
        },
      };
    }
  }
  if (codigo.length === 6) {
    return {
      tipo: 'codigo_interno_manual',
      data: {
        codigo_interno: codigo,
        peso: 0,
        digitoVerificador: 0,
      },
    };
  }
  return {
    tipo: 1,
    data: {},
  };
}

function getUnitarioCaixa(produto, unitario) {
  if(produto) {
    if (
      produto.unidade.tipo === POR_UNIDADE
      || produto.unidade.tipo === POR_PESO_FIXO
    ) {
      const pesoMedio = produto.unidade.tipo === POR_PESO_FIXO ? produto.peso_medio : 1;
      if (produto.nivelCaixa) {
        switch (produto.nivelCaixa) {
          case 1:
            return unitario / (produto.unidades_caixa_nivel_1 * pesoMedio);
          case 2:
            return unitario / (produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2 * pesoMedio);
          case 3:
            return unitario / (produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_3 * pesoMedio);
          default:
            return 0;
        }
      } else {
        return unitario;
      }
    } else {
      if (
        produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
      ) {
        if (produto.nivelCaixa) {
          switch (produto.nivelCaixa) {
            case 1:
              return unitario / (produto.peso_caixa_nivel_1);
            case 2:
              return unitario / (produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2);
            case 3:
              return unitario / (produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_3);
            default:
              return 0;
          }
        } else {
          return unitario;
        }
      } else {
        return unitario;
      }
    }
  } else {
    return 0
  }
}

function getPesoCaixa(produto) {
  switch(produto.unidade.tipo) {
    case POR_PESO_FIXO: {
      if (produto.nivelCaixa) {
        switch (produto.nivelCaixa) {
          case 1:
            return produto.unidades_caixa_nivel_1 * produto.peso_medio;
          case 2:
            return produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2 * produto.peso_medio;
          case 3:
            return produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_3 * produto.peso_medio;
          default:
            return 0;
        }
      } else {
        return 0;
      }
    }
    case POR_PESO_VARIAVEL_SEM_UNIDADE: {
      if (produto.nivelCaixa) {
        switch (produto.nivelCaixa) {
          case 1:
            return produto.peso_caixa_nivel_1;
          case 2:
            return produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2;
          case 3:
            return produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_3;
          default:
            return 0;
        }
      } else {
        return 0;
      }
    }
    default:
      return 0
  }
}

function changeCaixariaLocal(produto, setProduto, enqueueSnackbar) {
  const produto2 = {...produto}
  if(produto2) {
    const hasUnidade = produto2.unidade.tipo === POR_UNIDADE || produto2.unidade.tipo === POR_PESO_FIXO || produto2.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE;
    if(hasUnidade) {
      if((produto2.unidades_caixa_nivel_1 > 0 || produto2.peso_caixa_nivel_1 > 0)) {
        const nivelCaixaAntigo = produto2.nivelCaixa
        switch (produto2.nivelCaixa) {
          case 1:
            if(produto2.unidades_caixa_nivel_2 > 0) {
              produto2.nivelCaixa = 2
            } else {
              produto2.nivelCaixa = 0
            }
            break;
          case 2:
            if(produto2.unidades_caixa_nivel_3 > 0) {
              produto2.nivelCaixa = 3
            } else {
              produto2.nivelCaixa = 0
            }
            break;
          case 3:
            produto2.nivelCaixa = 0
            break;
          default:
            if(produto2.unidades_caixa_nivel_1 > 0 || produto2.peso_caixa_nivel_1 > 0) {
              produto2.nivelCaixa = 1
            } else {
              produto2.nivelCaixa = 0
            }
            break;
        }
        produto2.nivelCaixaAntigo = nivelCaixaAntigo ? nivelCaixaAntigo : -1
        setProduto(produto2)
        enqueueSnackbar('Nível de caixaria alterado!', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Este produto não possui caixaria!', {
          variant: 'error',
        });
      }
    }
  }
}

function getTotal2(peso, unidades, precoUnitario, produto) {
  if(produto) {
    if (produto.unidade.tipo === POR_UNIDADE) {
      if (produto.nivelCaixa) {
        return trunc10(unidades * precoUnitario, -2);
      }
      return trunc10(unidades * precoUnitario, -2);
    }
    if (produto.unidade.tipo === POR_PESO_FIXO) {
      if (produto.nivelCaixa) {
        return trunc10(unidades * precoUnitario, -2);
      }
      return trunc10(peso * precoUnitario, -2);
    }
    if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
      if (produto.nivelCaixa) {
        return trunc10(unidades * precoUnitario, -2);
      }
      return trunc10(peso * precoUnitario, -2);
    }
    return trunc10(peso * precoUnitario, -2);
  }
  return 0
}

function getUnidadesItemCaixa(produto, unidades) {
  if (
    produto.unidade.tipo === POR_UNIDADE
    || produto.unidade.tipo === POR_PESO_FIXO
  ) {
    if (produto.nivelCaixa) {
      switch (produto.nivelCaixa) {
        case 1:
          return unidades * produto.unidades_caixa_nivel_1;
        case 2:
          return unidades * (produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2);
        case 3:
          return unidades * (produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_3);
        default:
          return 0;
      }
    } else {
      return unidades;
    }
  } else {
    if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
      return 0;
    }
    return unidades;
  }
}

function getUnidadesCaixa(produto) {
  if (
    produto.unidade.tipo === POR_UNIDADE
    || produto.unidade.tipo === POR_PESO_FIXO
  ) {
    if (produto.nivelCaixa) {
      switch (produto.nivelCaixa) {
        case 1:
          return produto.unidades_caixa_nivel_1;
        case 2:
          return produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2;
        case 3:
          return produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_3;
        default:
          return 0;
      }
    } else {
      return 0;
    }
  } else {
    return 0;
  }
}

function pesoEfetivo(produto, unidades, peso) {
  if(produto) {
    switch(produto.unidade.tipo) {
      case POR_UNIDADE: {
        return 0
      }
      case POR_PESO_FIXO: {
        if (produto.nivelCaixa) {
          switch (produto.nivelCaixa) {
            case 1:
              return unidades * produto.unidades_caixa_nivel_1 * produto.peso_medio;
            case 2:
              return unidades * produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2 * produto.peso_medio;
            case 3:
              return unidades * produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_3 * produto.peso_medio;
            default:
              return unidades * produto.peso_medio;
          }
        } else {
          return unidades * produto.peso_medio;
        }
      }
      case POR_PESO_VARIAVEL_SEM_UNIDADE: {
        if (produto.nivelCaixa) {
          switch (produto.nivelCaixa) {
            case 1:
              return unidades * produto.peso_caixa_nivel_1;
            case 2:
              return unidades * produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2;
            case 3:
              return unidades * produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_3;
            default:
              return peso;
          }
        } else {
          return peso;
        }
      }
      case POR_PESO_VARIAVEL: {
        return peso
      }
      default:
        return 0
    }
  }
  return 0
}

function getValorCompraMedioImposto(produto) {
  if (produto) {
    if (produto.unidade.tipo === POR_UNIDADE) {
      if (produto.nivelCaixa) {
        switch (produto.nivelCaixa) {
          case 1:
            return produto.precoCompraMedioImpostos * produto.unidades_caixa_nivel_1;
          case 2:
            return produto.precoCompraMedioImpostos * (produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2);
          case 3:
            return produto.precoCompraMedioImpostos * (produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_3);
          default:
            return 0;
        }
      } else {
        return produto.precoCompraMedioImpostos;
      }
    }
    if (produto.unidade.tipo === POR_PESO_FIXO) {
      if (produto.nivelCaixa) {
        switch (produto.nivelCaixa) {
          case 1:
            return produto.precoCompraMedioImpostos * produto.peso_medio * produto.unidades_caixa_nivel_1;
          case 2:
            return produto.precoCompraMedioImpostos * produto.peso_medio * (produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2);
          case 3:
            return produto.precoCompraMedioImpostos * produto.peso_medio * (produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_3);
          default:
            return 0;
        }
      } else {
        return produto.precoCompraMedioImpostos;
      }
    }
    if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
      if (produto.nivelCaixa) {
        switch (produto.nivelCaixa) {
          case 1:
            return produto.precoCompraMedioImpostos * produto.peso_caixa_nivel_1;
          case 2:
            return produto.precoCompraMedioImpostos * (produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2);
          case 3:
            return produto.precoCompraMedioImpostos * (produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_3);
          default:
            return 0;
        }
      } else {
        return produto.precoCompraMedioImpostos;
      }
    }
    return produto.precoCompraMedioImpostos;
  }
  return 0;
}

function getLabelNivelCaixaria(nivel) {
  if (nivel === 1) {
    return 'cx1';
  }

  if (nivel === 2) {
    return 'cx2';
  }

  if (nivel === 3) {
    return 'cx3';
  }

  return '';
}

function getValueCaixaria(tipo) {
  switch (tipo) {
    case TIPO_UNIDADE_OFERTA_KG:
      return 0;
    case TIPO_UNIDADE_OFERTA_UN:
      return 0;
    case TIPO_UNIDADE_OFERTA_CAIXA:
      return 1;
    case TIPO_UNIDADE_OFERTA_CAIXETA:
      return 2;
    case TIPO_UNIDADE_OFERTA_CAIXA_MAE:
      return 3;
    default:
      return '';
  }
}

////////////////////////////////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////////

function getPrecoUnitarioVerdadeiro3(produto, unidades, peso) {
  if (produto.ofertasProdutos.length > 0) {
    let preco = produto.preco_venda;
    for (let i = 0; i < produto.ofertasProdutos.length; i++) {
      const oferta = produto.ofertasProdutos[i];
      if (oferta.tipo !== TIPO_OFERTA_PRODUTO_LIBERACAO) {
        const precoOferta = oferta.valor;
        if (preco > precoOferta) {
          preco = precoOferta;
        }
      }
    }
    return preco;
  }
  return produto.preco_venda;
}

function getPrecoUnitarioVerdadeiroCaixa3(produto, unidades, peso) {
  const preco = getPrecoUnitarioVerdadeiro3(produto, unidades, peso);
  if (produto.nivelCaixa) {
    switch (produto.nivelCaixa) {
      case 1: {
        const qtde = getQuantidadeEfetiva(produto, 1);
        return preco * qtde * fatorPesoMedio(produto);
      }
      case 2: {
        const qtde = getQuantidadeEfetiva(produto, 2);
        return preco * qtde * fatorPesoMedio(produto);
      }
      case 3: {
        const qtde = getQuantidadeEfetiva(produto, 3);
        return preco * qtde * fatorPesoMedio(produto);
      }
      default:
        return preco;
    }
  } else {
    return preco;
  }
}

////////////////////////////////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////////


export {
  getPrecoUnitarioVerdadeiroCaixa,
  getPrecoUnitarioVerdadeiroCaixa2,
  getQuantidadeCaixaria,
  getQuantidadeCaixariaLabel,
  getQuantidadeEfetiva,
  getNivelCaixaQtde,
  getLabelTipoUnidade,
  getNivelCaixaCodigo,
  getTotalCaixaTipoOferta,
  getLabelTipoUnidadeCaixaria,
  getOptionsTipoUnidadeOferta,
  getLabelUnidade,
  getPrecos,
  getNomeProduto,
  analisaCodigo,
  getUnitarioCaixa,
  getPesoCaixa,
  changeCaixariaLocal,
  getTotal2,
  getUnidadesItemCaixa,
  getUnidadesCaixa,
  pesoEfetivo,
  getValorCompraMedioImposto,
  getLabelNivelCaixaria,
  getValueCaixaria,
  getPrecoUnitarioVerdadeiroCaixa3
};
