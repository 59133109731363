import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useContext,
  useEffect
} from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import Divider from '@material-ui/core/Divider';

import {
  IntegerInput,
  SearchProduto,
  PesoInput
} from '../../../components';
import {
  getListAllAPI,
} from '../../../services';
import {
  formatPeso,
  round10,
  STATUS_ATIVO,
  SECONDARY_COLOR,
  SECONDARY_COLOR_OFFLINE,
  ATALHO_AVANCAR,
  ATALHO_VOLTAR,
  PRIMARY_COLOR,
  PRIMARY_COLOR_OFFLINE
} from '../../../utils';
import DialogoDeletarPesagem from './DialogoDeletarPesagem';
import { AppContext } from '../../../app/context';
import { WeightInput } from '../../Inputs/WeightInput'

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: SECONDARY_COLOR,
    color: theme.palette.common.white,
    padding: 3
  },
  body: {
    fontSize: 14,
    padding: 3
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ESC_ACTION = 'Escape';
const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const ADICIONAR_ITEM_ACTION = 'F1';
const DELETAR_ITEM_ACTION = 'F2';

const CONSULTOR_PRECOS_ACTION = 'F3';

const DialogoCategoriaTransacao = forwardRef(({ handleClose = () => {}, handleSalvar2 = () => {}, openConsultorPrecos= () => {} }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [carregando, setCarregando] = useState(false);
  const [produto, setProduto] = useState(null);
  const [numeroCaixas, setNumeroCaixas] = useState(0);
  const [taraUnitaria, setTaraUnitaria] = useState(0);
  const [pesagens, setPesagens] = useState([]);
  const [searchProduto, setSearchProduto] = useState('');
  const refDialogoPesagem = useRef(null);
  const refDialogoDeletarPesagem = useRef(null);
  const [peso, setPeso] = useState(0);

  const { app: { modoOffline } } = useContext(AppContext);

  const classes = useStyles();

  /////

  const refSearchProduto = useRef(null);
  const refInputTaraUnitaria = useRef(null);
  const refInputNCaixas = useRef(null);
  const refInputPeso = useRef(null);

  /////

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  const handleCloseDialogLocal = () => {
    setOpen(false);
    handleClose();
  };

  async function reiniciar() {
    setNumeroCaixas(0);
    setPesagens([]);
    setTaraUnitaria(0);
    setProduto(null);
    setSearchProduto('');
    setCarregando(false);
  }

  function getValoresComputados() {
    const taraTotal = (taraUnitaria / 1000) * numeroCaixas;
    let pesoBrutoTotal = 0;
    for (let i = 0; i < pesagens.length; i++) {
      pesoBrutoTotal += pesagens[i];
    }
    const pesoLiquidoTotal = pesoBrutoTotal - taraTotal;

    return {
      taraTotal,
      pesoBrutoTotal,
      pesoLiquidoTotal,
    };
  }

  const {
    pesoBrutoTotal,
    pesoLiquidoTotal,
    taraTotal,
  } = getValoresComputados();

  function toStringPesagens(pesagens) {
    return pesagens.map((value) => round10(value, -3).toFixed(3)).join(';');
  }

  async function handleSalvar() {
    if(!erroExistente) {
      if (pesagens.length > 0) {
        if (pesoLiquidoTotal > 0) {
          setCarregando(true);
          try {
            
            handleSalvar2({
              produto,
              tara_unitaria: taraUnitaria / 1000,
              tara_total: taraTotal,
              numero_caixas: numeroCaixas,
              peso_bruto_total: pesoBrutoTotal,
              peso_liquido_total: pesoLiquidoTotal,
              pesagens: toStringPesagens(pesagens),
            })

            handleCloseDialog();
          } catch (erros) {
            enqueueSnackbar(`${erros}`, {
              variant: 'error',
            });
            handleCloseDialog();
          }
        } else {
          enqueueSnackbar(
            `O total de tara está maior que o peso bruto da mercadoria!!!`,
            {
              variant: 'error',
            },
          );
        }
      } else {
        enqueueSnackbar(`Deve haver ao menos uma pesagem!!!`, {
          variant: 'error',
        });
      }
    } else {
      enqueueSnackbar(`Preencha os campos corretamente!!!`, {
        variant: 'error',
      });
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen() {
      reiniciar();
      setOpen(true);
    },
  }));

  async function searchHandleProdutos(codigo, id) {
    const produtoData = await getListAllAPI(
      'produtos',
      ['id', 'asc'],
      id
        ? { id, ofertasProdutos: true }
        : { codigo, is_kit: false, is_caixa: false, status: STATUS_ATIVO, ofertasProdutos: true },
      ['unidade'],
    );
    if (produtoData.data.length > 0) {
      const produto = produtoData.data[0];
      setProduto(produto);
    }
    setSearchProduto('');
    if(refInputTaraUnitaria.current) {
      refInputTaraUnitaria.current.focus();
      refInputTaraUnitaria.current.select();
    }
  }

  function validateProduto() {
    let error = '';
    if (!produto) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateTaraUnitaria() {
    let error = '';
    if (taraUnitaria < 0 || Number.isNaN(taraUnitaria)) {
      error = 'Valor inválido';
    }
    return error;
  }

  function validateNumeroCaixas() {
    let error = '';
    if (numeroCaixas < 0 || Number.isNaN(numeroCaixas)) {
      error = 'Valor inválido';
    }
    return error;
  }

  function getErros() {
    const errosOld = ['', '', ''];
    errosOld[0] = validateProduto();
    errosOld[1] = validateTaraUnitaria();
    errosOld[2] = validateNumeroCaixas();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  function deletarPesagem() {
    if (refDialogoDeletarPesagem.current) {
      refDialogoDeletarPesagem.current.handleOpen();
    }
  }

  /////////////////////////////////

  function adicionarPesagem() {
    if(peso <= 0 || Number.isNaN(peso)) {
      enqueueSnackbar(`A pesagem deve ser um número maior que zero!!!`, {
        variant: 'error',
      });
    } else {
      const pesagens2 = [...pesagens]
      pesagens2.push(peso)
      setPesagens(pesagens2)
      setPeso(0)
    } 
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if(open) {
        if(refSearchProduto.current) {
          refSearchProduto.current.focus();
          //refSearchProduto.current.select();
        }
      }
    }, 200);

    return () => clearTimeout(timeoutId);
  }, [open])

  /* useEffect(() => {
    if(refSearchProduto.current) {
      refSearchProduto.current.focus();
      refSearchProduto.current.select();
    }
  }, []) */

  function handleActions(action) {
    switch (action) {
      case ESC_ACTION:
        handleCloseDialogLocal()
        break;
      case CANCELAR_VENDA_ACTION:
        handleCloseDialogLocal()
        break;
      case FECHAR_VENDA_ACTION: {
        handleSalvar()
        break;
      }
      case ADICIONAR_ITEM_ACTION: {
        adicionarPesagem()
        break;
      }
      case DELETAR_ITEM_ACTION:
        deletarPesagem()
        break;
      case CONSULTOR_PRECOS_ACTION:
        openConsultorPrecos();
        break;
      default:
        break;
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth={produto ? "md" : 'sm'}
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          style={{
            backgroundColor: modoOffline ? SECONDARY_COLOR_OFFLINE : SECONDARY_COLOR, 
            color: 'white',
            textAlign: 'center'
          }}
        >
          {'Conferência de Grandes Pesagens'}
        </DialogTitle>
        <DialogContent
          style={{
            margin: '0',
          }}
          dividers
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div style={{
              display: 'flex',
              //gap: '20px'
            }}>
              <div
                style={{
                  //marginTop: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  //gap: '20px',
                  flex: 1
                }}
              >
                <SearchProduto
                    label="Pesquisar produto"
                    value={searchProduto}
                    onChange={(e) => setSearchProduto(e)}
                    fullwidth
                    searchHandle={searchHandleProdutos}
                    handleKey={(keyCode, keyName) => {
                      handleActions(keyName);
                    }}
                    resource="produtos/search/autocomplete_grandes_pesagens"
                    autoFocus={true}
                    ref={refSearchProduto}
                    isPaper={false}
                  />

                  <div style={{ margin: '10px' }} ></div>

                {produto ? (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        //gap: '10px',
                        border: `1px solid ${ modoOffline ? PRIMARY_COLOR_OFFLINE : PRIMARY_COLOR}`/* marginLeft: '10px' */, padding: '5px', borderRadius: '1px',
                      }}
                    >
                      <div style={{ marginBottom: '10px' }}>
                        <span
                          style={{
                            fontWeight: 'bold',
                            marginRight: '10px',
                          }}
                        >
                          Produto:
                        </span>
                        <span>{`${produto.nome}`}</span>
                      </div>
                      <div style={{ marginBottom: '10px' }}>
                        <span
                          style={{
                            fontWeight: 'bold',
                            marginRight: '10px',
                          }}
                        >
                          Tara Total:
                        </span>
                        <span>{`${formatPeso(taraTotal)} Kg`}</span>
                      </div>
                      <div style={{ marginBottom: '10px' }}>
                        <span
                          style={{
                            fontWeight: 'bold',
                            marginRight: '10px',
                          }}
                        >
                          Peso Bruto Total:
                        </span>
                        <span>{`${formatPeso(pesoBrutoTotal)} Kg`}</span>
                      </div>
                      <div>
                        <span
                          style={{
                            fontWeight: 'bold',
                            marginRight: '10px',
                          }}
                        >
                          Peso Líquido Total:
                        </span>
                        <span>{`${formatPeso(pesoLiquidoTotal)} Kg`}</span>
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        //gap: '10px',
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <IntegerInput
                          ref={refInputTaraUnitaria}
                          handleEnter={() => {
                            if(refInputNCaixas.current) {
                              refInputNCaixas.current.focus();
                              refInputNCaixas.current.select();
                            }
                          }}
                          label="Tara Unitária (gramas)"
                          handleKey={(keyCode, keyName) => {
                            handleActions(keyName);
                          }}
                          value={
                            Number.isNaN(taraUnitaria) ? '' : String(taraUnitaria)
                          }
                          onChange={(value) =>
                            setTaraUnitaria(parseInt(value.target.value, 10))
                          }
                          error={erros[1] !== ''}
                          helperText={erros[1]}
                          fullWidth
                        />
                      </div>

                      <div style={{ marginLeft: '10px', flex: 1 }}>
                        <IntegerInput
                          ref={refInputNCaixas}
                          handleEnter={() => {
                            if(refInputPeso.current) {
                              refInputPeso.current.focus();
                              refInputPeso.current.select();
                            }
                          }}
                          label="Nº de Caixas"
                          handleKey={(keyCode, keyName) => {
                            handleActions(keyName);
                          }}
                          value={
                            Number.isNaN(numeroCaixas) ? '' : String(numeroCaixas)
                          }
                          onChange={(value) =>
                            setNumeroCaixas(parseInt(value.target.value, 10))
                          }
                          error={erros[2] !== ''}
                          helperText={erros[2]}
                          fullWidth
                        />
                        </div>
                      </div>
                  </>
                ) : null}
              </div>
              { produto ? <>
              <Divider orientation="vertical" flexItem style={{ margin: '0 20px' }}/>
              <div style={{
                flex: 1
              }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  //gap: '3px',
                  color: 'red',
                  margin: '5px 0'
                }}>
                  <div style={{ marginBottom: '3px' }}>{`* ${ADICIONAR_ITEM_ACTION} - Adiconar Nova Pesagem`}</div>
                  <div>{`* ${DELETAR_ITEM_ACTION} - Deletar Pesagem`}</div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '10px 0',
                  }}
                >
                  <h1 style={{ textAlign: 'center' }}>Pesagens</h1>
                 
                </div>
                <div>
                  <WeightInput
                    ref={refInputPeso}
                    handleEnter={() => {
                      adicionarPesagem()
                    }}
                    label="Peso"
                    handleKey={(keyCode, keyName) => {
                      handleActions(keyName);
                    }}
                    value={Number.isNaN(peso) ? '' : String(peso)}
                    onChange={(value) => {
                      setPeso(parseFloat(value.target.value))
                    }}
                    helperText={`

                    `}
                    fullWidth
                    style={{
                      padding: '0px'
                    }}
                  />
                </div>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Ordem</StyledTableCell>
                        <StyledTableCell>Peso</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pesagens.map((row, ind) => (
                        <StyledTableRow key={`${ind}-row`}>
                          <StyledTableCell>{ind + 1}</StyledTableCell>
                          <StyledTableCell>
                            {formatPeso(row)}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              </> : null}
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={handleCloseDialogLocal}
            variant="contained"
            color="secondary"
            tabindex="-1"
          >
            {`Cancelar (${CANCELAR_VENDA_ACTION})`}
          </Button>
          <Button
            onClick={handleSalvar}
            variant="contained"
            color="secondary"
            tabindex="-1"
          >
            {`Salvar (${FECHAR_VENDA_ACTION})`}
          </Button>
        </DialogActions>
      </Dialog>
      <DialogoDeletarPesagem
        ref={refDialogoDeletarPesagem}
        handleDelete={(id) => {
          setPesagens(pesagens.filter((item, index) => index !== id -1));
        }}
        openConsultorPrecos={openConsultorPrecos}
      />
    </div>
  );
});

export default DialogoCategoriaTransacao;
