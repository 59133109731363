import React from 'react';
import { Redirect } from 'react-router-dom';

import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import {
  isAuthenticated,
} from '../../../services';

import {
  IMAGEM_BACKGROUND
} from '../../../utils'

const Background = ({
  children,
  isInit = false,
}) => (
  <>
    { isInit ? (
      <Box
        bgcolor="#FFCFF9"
        padding="0px"
        height="100%"
        display="grid"
        gridTemplateColumns="1fr"
        gridTemplateRows="1fr 10fr"
        css={{
          background:
          `url(${IMAGEM_BACKGROUND})`,
          backgroundSize: 'cover',
        }}
      >
        {!isAuthenticated() ? <Redirect to="/login" /> : children}
      </Box>
    )
      : (
        <Box
          bgcolor="#FFCFF9"
          padding="0px"
          height="100%"
          css={{
            background:
            `url(${IMAGEM_BACKGROUND})`,
            backgroundSize: 'cover',
          }}
        >
          {!isAuthenticated() ? <Redirect to="/login" /> : children}
        </Box>
      )}
  </>
);

Background.propTypes = {
  isRedirect: PropTypes.bool,
  redirect: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Background;
