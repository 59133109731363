import React, {
  useReducer,
  useEffect,
  useState,
  useRef
} from 'react';
import {
  BrowserRouter as Router, Route,
} from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core';
import { SnackbarProvider, useSnackbar } from 'notistack';

import PreVendas from '../Pages/CreatePreVenda';
import Vendas from '../Pages/CreateVenda';
import InitPage from '../Pages/Init';
import LoginPage from '../Pages/Login';
import theme from '../themes/default';
import themeOffline from '../themes/themeOffline';
import { AppContext } from './context';
import applyMiddleware from './middleware';
import reducer from './reducer';
import RouteBackground from './RouteBackground';
import { APP_VERSION, URL_SERVIDOR_IMPRESSORA } from '../utils'
import { api, getListAllAPI } from '../services'
import {
  DialogoConsultorPrecos,
} from '../components';

const tempoPing = 5 * 1000

function MensagemDesatualizado({ oldVersion, newVersion }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        color: 'red',
      }}
    >
      <h1
        style={{
          textAlign: 'center',
        }}
      >
        FC desatualizado, se quer voltar a usa-lo, por favor abre e feche
        algumas vezes até atualizar
      </h1>
      <h2
        style={{
          textAlign: 'center',
        }}
      >{`Versão Atual: ${oldVersion}`}</h2>
      <h2
        style={{
          textAlign: 'center',
        }}
      >{`Versão Nova: ${newVersion}`}</h2>
    </div>
  );
}

function App() {
  return (
    <>
      <RouteBackground exact path="/" component={InitPage} isInit />
      <RouteBackground path="/vendas" component={Vendas} />
      <RouteBackground path="/pre_vendas" component={PreVendas} />
      <Route path="/login" component={LoginPage} />
    </>
  );
}

function WapperApp() {
  const [app, dispatch] = useReducer(reducer, {
    saldoCaixa: 0,
    mensagemInicialFc: '',
    saldoIndicadorRetirada: 1500,
    meiosPagamento: [],
    limiteInferiorNotaCpf: 5000,
    limiteSuperiorNotaCpf: 10000,
    videosTutoriais: [],
    contasPixADistancia: [],
    modoOffline: false,
    isBuscouDadosConfig: false,
    ////////
    criarVendaPermissao: false,
    consultarPrecosPermissao: false,
    utilizarTrocoPermissao: false,
    utilizarTrocoAberturaCaixaPermissao: false,
    criarPedidoPermissao: false,
    criarRetiradaPermissao: false,
    carregarPedidoPermissao: false,
    criarClientePermissao: false,
    editarClientePermissao: false,
    tipoPagamentoDinheiroPermissao: false,
    tipoPagamentoCartaoCreditoPermissao: false,
    tipoPagamentoCartaoDebitoPermissao: false,
    tipoPagamentoPixQrcodePermissao: false,
    tipoPagamentoVoucherPermissao: false,
    tipoPagamentoDevolucaoPermissao: false,
    tipoPagamentoPixDistanciaPermissao: false,
    tipoPagamentoCreditoFidelidadePermissao: false,
    pesquisaPorNomes: false,
    tipoCaixa: '',
    nomeCaixa: '',
    isBuscouDadosPermissoes: false,
    ////////////////
    valorMaximoPesoNoFc: 25,
    //
    pesquisaPorCodigoDeBalancaPermissao: false,
    conferenciaGrandesPesagensPermissao: false
  });
  const [version, setVersion] = useState(APP_VERSION);
  const { enqueueSnackbar } = useSnackbar();
  const refCont = useRef(0)
  const refDialogoConsultorPrecos = useRef(null);

  async function testaPing() {
    try {
      const data = await fetch(`${URL_SERVIDOR_IMPRESSORA}/ping`)
      const dados = await data.json()
      dispatch({ type: 'CHANGE_MODO_OFFLINE', modoOffline: !dados.status })
    } catch(e) {
      dispatch({ type: 'CHANGE_MODO_OFFLINE', modoOffline: false })
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      getDados()
      testaPing()
      refCont.current = 1
    }, tempoPing);
    return () => clearInterval(timer);
  });

  async function getVersion() {
    const response = await api.get('/versions');
    const { fc } = response.data;
    
    setVersion(fc);
  }

  useEffect(() => {
    try {
      getVersion();
    } catch(e) {

    }
  }, []);

  async function getMensagemInicialFc() {
    try {
      const response = await api.get('/config/1');
      const { 
        mensagem_tela_inicial_fc, 
        saldo_indicador_retirada ,
        limite_inferior_nota_cpf,
        limite_superior_nota_cpf,
        peso_maximo_no_fc
      } = response.data;

      /* dispatch({ type: 'UPDATE_MENSAGEM_TELA_INICIAL_FC', mensagemInicialFc: mensagem_tela_inicial_fc })
      dispatch({ type: 'UPDATE_IS_BUSCOU_DADOS_CONFIG', isBuscouDadosConfig: true })
      dispatch({ type: 'UPDATE_SALDO_INDICADOR_RETIRADA', saldoIndicadorRetirada: saldo_indicador_retirada })
      dispatch({ 
        type: 'UPDATE_LIMITES_CPF_NOTA', 
        limiteInferiorNotaCpf: limite_inferior_nota_cpf,
        limiteSuperiorNotaCpf: limite_superior_nota_cpf,
      }) */

      dispatch({ 
        type: 'UPDATE_DADOS_CONFIG', 
        saldoIndicadorRetirada: saldo_indicador_retirada,
        isBuscouDadosConfig: true,
        limiteInferiorNotaCpf: limite_inferior_nota_cpf,
        limiteSuperiorNotaCpf: limite_superior_nota_cpf,
        mensagemInicialFc: mensagem_tela_inicial_fc,
        valorMaximoPesoNoFc: peso_maximo_no_fc,
      })
    } catch(e) {
      enqueueSnackbar('INTERNET: ERRO AO CARREGAR CONFIGURAÇÕES!', {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    getMensagemInicialFc();
  }, []);

  async function getMeioPagamentos() {
    try {
      const dataTiposPagamento = await getListAllAPI(
        'tipospagamento',
        ['id', 'asc'],
        { ativo: true },
        [],
      );

      dispatch({ type: 'UPDATE_MEIOS_PAGAMENTO', meiosPagamento: dataTiposPagamento.data })
    } catch(e) {
      enqueueSnackbar('INTERNET: ERRO AO CARREGAR MEIOS DE PAGAMENTOS!', {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    getMeioPagamentos();
  }, []);

  async function getVideosTutoriais() {
    try {
      const dataVideosTutoriais = await getListAllAPI(
        'videos_tutoriais_fc',
        ['data_postagem', 'desc'],
        {  },
        [],
      );

      dispatch({ type: 'UPDATE_VIDEOS_TUTORIAIS', videosTutoriais: dataVideosTutoriais.data })
    } catch(e) {
      //
    }
  }

  useEffect(() => {
    getVideosTutoriais();
  }, []);

  async function getContasPixADistancia() {
    try {
      const dataContasPixADistancia = await api.get('get-contas-pix-a-distancia');
      dispatch({ type: 'UPDATE_CONTAS_PIX_A_DISTANCIA', contasPixADistancia: dataContasPixADistancia.data })
    } catch(e) {
      enqueueSnackbar('INTERNET: ERRO AO CARREGAR CONTAS DE PIX A DISTÂNCIA!', {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    getContasPixADistancia();
  }, []);

  const isAtualizado = version === APP_VERSION || app.modoOffline;

  async function getDados() {
    if(refCont.current) {
      if(app.contasPixADistancia.length === 0) {
        await getContasPixADistancia()
      }
      if(app.meiosPagamento.length === 0) {
        await getMeioPagamentos()
      }
      if(!app.isBuscouDadosConfig) {
        await getMensagemInicialFc()
      }
    }
  }

  function openConsultorPrecos() {
    if (refDialogoConsultorPrecos.current) {
      refDialogoConsultorPrecos.current.handleOpen();
    }
  }

  return (
    <>
      {isAtualizado ? (
        <div className="App" style={{ height: '100vh', width: '100vw' }}>
          <ThemeProvider theme={ app.modoOffline ? themeOffline : theme}>
              <AppContext.Provider value={{ app, dispatch: applyMiddleware(dispatch), openConsultorPrecos }}>
                <Router>
                  <App />
                </Router>
              </AppContext.Provider>
          </ThemeProvider>
          <DialogoConsultorPrecos ref={refDialogoConsultorPrecos} />
        </div>
      ) : (
        <MensagemDesatualizado oldVersion={APP_VERSION} newVersion={version} />
      )}
    </>
  );
}

function WapperApp1() {
  return (
    <SnackbarProvider maxSnack={6}>
      <WapperApp/>
    </SnackbarProvider>
  );
}

export default WapperApp1;
