import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useContext
} from 'react';

import {
  Box, makeStyles,
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import {
  getListAllAPI,
} from '../../../services';
import {
  formatCPF,
  formatCNPJ,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  ATALHO_AVANCAR,
  ATALHO_VOLTAR,
  STATUS_ATIVO,
  PRIMARY_COLOR_OFFLINE,
  SECONDARY_COLOR_OFFLINE
} from '../../../utils';
import {
  AutoCompleteRemoto,
} from '../../Inputs';

import { DialogoCliente } from '..';

import { AppContext } from '../../../app/context';

const useStyles = makeStyles((theme) => ({
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  textField: {},
  btnImprimir: {
    backgroundColor: theme.palette.secondary.main,
    opacity: 0.4,
  },
  header: {
    height: '17%',
  },
  body: {
    height: '83%',
  },
  footer: {
    height: '15vh',
    padding: '10px',
    paddingBottom: '20px',
  },
  textOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;

const EDITAR_CLIENTE_ACTION = 'F7';
const ESC_ACTION = 'Escape';

const CONSULTOR_PRECOS_ACTION = 'F3';

const DialogoConfirmacao = forwardRef((
  { handleSelecionado = () => {}, handleFechado = () => {}, dispatch, openConsultorPrecos= () => {} },
  ref,
) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const refDialogoCliente = useRef(null);
  const classes = useStyles();
  const [cliente, setCliente] = useState(null);

  const { app: { modoOffline } } = useContext(AppContext);

  const colorHeader = modoOffline ? SECONDARY_COLOR_OFFLINE : SECONDARY_COLOR;

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
      setCliente(null);
    },
  }));

  const handleClose = () => {
    setOpen(false);
    handleFechado();
  };

  const handleSalvar = () => {
    if (cliente) {
      setOpen(false);
      handleSelecionado();
    } else {
      enqueueSnackbar(`${'É necessário especificar o cliente!'}`, {
        variant: 'error',
      });
    }
  };

  function editarCliente() {
    if (refDialogoCliente.current) {
      const idCliente = cliente ? cliente.id : 0;
      if (idCliente > 0) {
        refDialogoCliente.current.handleEdit(idCliente);
      }
    }
  }

  function handleActions(name) {
    switch (name) {
      case CANCELAR_VENDA_ACTION:
        handleClose();
        break;
      case FECHAR_VENDA_ACTION:
        handleSalvar(cliente);
        break;
      case EDITAR_CLIENTE_ACTION:
        editarCliente();
        break;
      case ESC_ACTION:
        handleClose();
        break;
      case CONSULTOR_PRECOS_ACTION:
        openConsultorPrecos();
        break;
      default:
        break;
    }
  }

  async function updateCliente(idUpdate) {
    try {
      const data = await getListAllAPI(
        'clientes',
        ['id', 'asc'],
        { id: [idUpdate] },
        ['cidade.estado', 'categoria'],
      );
      if (data.data.length > 0) {
        dispatch({ type: 'UPDATE_CLIENTE', cliente: data.data[0] });
        setCliente(data.data[0]);
      } else {
        throw 'Este cliente não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`INTERNET: ERRO AO OBTER O CLIENTE!`, {
        variant: 'error',
      });
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title" style={{ color: 'white', backgroundColor:  modoOffline ? SECONDARY_COLOR_OFFLINE : SECONDARY_COLOR, textAlign: 'center' }}>COMPATIBILIZAR CLIENTE</DialogTitle>
        <DialogContent>
          <Box
            padding="10px"
            className={classes.header}
          >
            <Box
              display="flex"
              css={{
                backgroundColor: 'white', opacity: '0.75', height: '100%', padding: '0px 10px', flexDirection: 'column',
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                flex={1}
              >
                <AutoCompleteRemoto
                  id="cliente"
                  name="cliente"
                  label="Responsável"
                  value={cliente}
                  onChange={(value) => setCliente(value)}
                  handleKey={(keyCode, keyName) => {
                    handleActions(keyName);
                  }}
                  handleEnter={() => {

                  }}
                  resource="clientes"
                  nested={['cidade.estado', 'categoria']}
                  toString={false}
                  fullwidth
                  autoFocus
                  filters={{ ativo: true, status: STATUS_ATIVO }}
                  getOptionSelected={(option, value) => option.nome === value.nome}
                  getOptionLabel={(option) => `${option.nome}${option.nome_fantasia ? ` - (${option.nome_fantasia})` : ''}`}
                />
                <AutoCompleteRemoto
                  id="cliente"
                  name="cliente"
                  label="Nome Fantasia"
                  value={cliente}
                  onChange={(value) => setCliente(value)}
                  handleKey={(keyCode, keyName) => {
                    handleActions(keyName);
                  }}
                  handleEnter={() => {

                  }}
                  resource="clientes"
                  campo="nome_fantasia"
                  nested={['cidade.estado', 'categoria']}
                  toString={false}
                  style={{ marginLeft: '10px' }}
                  fullwidth
                  filters={{ ativo: true, status: STATUS_ATIVO }}
                />
              </Box>
              {cliente && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flex={1}
                  style={{
                    border: `1px solid ${ modoOffline ? PRIMARY_COLOR_OFFLINE : PRIMARY_COLOR}`, marginLeft: '0px', padding: '5px', borderRadius: '5px', marginBottom: '10px',
                  }}
                >
                  <Box marginLeft="10px" display="flex" flexDirection="column" flex={1}>
                    <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1}>
                      <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Responsável:</p>
                      {cliente.nome}
                    </Box>
                    <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1}>
                      <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Razão Social:</p>
                      {cliente.razao_social}
                    </Box>
                    <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1}>
                      <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>CPF:</p>
                      {formatCPF(cliente.cpf)}
                    </Box>
                    <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1}>
                      <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>CNPJ:</p>
                      {formatCNPJ(cliente.cnpj)}
                    </Box>
                  </Box>
                  <Box marginLeft="10px" display="flex" flexDirection="column" flex={1}>
                    <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1} justifyContent="flex-start">
                      <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Nome Fantasia:</p>
                      {cliente.nome_fantasia}
                    </Box>
                    <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1} justifyContent="flex-start">
                      <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Bairro:</p>
                      {cliente.bairro}
                    </Box>
                    <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1} justifyContent="flex-start">
                      <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Cidade:</p>
                      {cliente.cidade ? `${cliente.cidade.nome} - ${cliente.cidade.estado.sigla}` : ''}
                    </Box>
                    <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1} justifyContent="flex-start">
                      <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Logradouro:</p>
                      {cliente.logradouro}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            color="secondary"
            variant="contained"
            onClick={editarCliente}
            tabindex="-1"
          >
            {`Editar cliente (${EDITAR_CLIENTE_ACTION})`}
          </Button>
          <Button
            onClick={handleClose}
            color="secondary"
            variant="contained"
            tabindex="-1"
          >
            {`Fechar (${CANCELAR_VENDA_ACTION})`}
          </Button>
        </DialogActions>
      </Dialog>
      <DialogoCliente
        ref={refDialogoCliente}
        handleClose={(idUpdate) => {
          if (idUpdate > 0) {
            updateCliente(idUpdate);
          }
        }}
        openConsultorPrecos={openConsultorPrecos}
      />
    </div>
  );
});

export default DialogoConfirmacao;
