import { createContext } from 'react';

export const VendaContext = createContext({
  venda: {
    cliente: null,
    itens: [],
    itensExcluidos: [],
    parcelas: [],
    subTotal: 0,
    idEdit: -1,
    isEditPrice: false,
    tipoVenda: 0,
    isEmPartes: false,
    cpf: null,
    uuid: null
  },
  dispatch: () => {},
});
