import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useContext,
} from 'react';

import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@material-ui/core';

import { AppContext } from '../../../app/context';
import { formatMoeda } from '../../../utils';

const CONSULTOR_PRECOS_ACTION = 'F3';

const DialogoLogout = forwardRef(({ openConsultorPrecos= () => {} }, ref) => {
  const [open, setOpen] = useState(false);
  const { dispatch, app: { saldoCaixa } } = useContext(AppContext);

  function updateSaldo() {
    dispatch({
      type: 'UPDATE_SALDO_CAIXA',
    });
  }

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
      updateSaldo();
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSalvar = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">Encerrar sessão!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Deseja realmente encerrar a sessão? (diferença = ${formatMoeda(0 - saldoCaixa)})` }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleSalvar} color="secondary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoLogout;
