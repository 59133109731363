import React, {
  forwardRef,
  useEffect,
} from 'react';

import { CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { getListAPI } from '../../../../services';

import { useSnackbar } from 'notistack';

const AutoCompleteProdutos = forwardRef(
  (
    {
      value,
      onChange,
      inputValue,
      updateValue,
      valueDebounce,
      label,
      handleKey,
      resource,
      nested,
      fullWidth = true,
      style = {},
      autoFocus = false,
      filters,
      campo = 'nome',
      getOptionSelected = (option, value) => option[campo] === value[campo],
      getOptionLabel = (option) => option[campo],
      disabled = false,
      campoPesquisa,
      ...rest
    },
    forwardedRef,
  ) => {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
      (async () => {
        if (open) {
          try {
            const filter = { ...filters };
            filter[campoPesquisa || campo] = valueDebounce;
            const produtos = await getListAPI(
              resource,
              ['id', 'asc'],
              [1, 10],
              filter,
              nested,
            );
            setOptions(produtos.data);
          } catch(e) {
            setOptions([]);
            enqueueSnackbar(
              `INTERNET: ERRO AO BUSCAR DADOS NESSE CAMPO DE PESQUISA!`,
              {
                variant: 'error',
              },
            );
          }
        } else {
          setOptions([]);
        }
      })();
    }, [open]);

    useEffect(() => {
      (async () => {
        try {
          const filter = { ...filters };
          filter[campoPesquisa || campo] = valueDebounce;
          const produtos = await getListAPI(
            resource,
            ['id', 'asc'],
            [1, 10],
            filter,
            nested,
          );
          setOptions(produtos.data);
        } catch(e) {
          setOptions([]);
        }
      })();
    }, [valueDebounce]);

    return (
      <Autocomplete
        style={style}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        value={value}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          updateValue(newInputValue);
        }}
        getOptionSelected={getOptionSelected}
        getOptionLabel={getOptionLabel}
        options={options}
        loading={loading}
        fullWidth={fullWidth}
        autoFocus={autoFocus}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            margin="normal"
            {...rest}
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            inputRef={forwardedRef}
            autoComplete="off"
            onKeyDown={(e) => {
              handleKey(e.keyCode, e.key);
            }}
            autoFocus={autoFocus}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    );
  },
);

export default AutoCompleteProdutos;
