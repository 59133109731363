import {
  getCaixaId,
  getListAllAPI,
} from '../services';

const applyMiddleware = (dispatch) => async (action) => {
  switch (action.type) {
    case 'UPDATE_SALDO_CAIXA': {
      try {
        const data = await getListAllAPI(
          'contas',
          ['id', 'asc'],
          { id: [getCaixaId()] },
          [],
        );
        dispatch({
          type: 'UPDATE_SALDO_CAIXA',
          saldoCaixa: data.data[0].saldoAtual,
        });
      } catch(e) {
        //
      }
      break;
    }
    default:
      dispatch(action);
      break;
  }
};

export default applyMiddleware;
