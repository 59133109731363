import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useContext,
  useEffect
} from 'react';
  
import {
  Dialog, DialogContent,
} from '@material-ui/core';
  
import {
  SECONDARY_COLOR,
  SECONDARY_COLOR_OFFLINE
} from '../../../utils';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { TextInput } from '../../../components'
import { AppContext } from '../../../app/context';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const CONSULTOR_PRECOS_ACTION = 'F3';
  
const DialogoConfirmacao = forwardRef(({ openConsultorPrecos= () => {} }, ref) => {
  const [open, setOpen] = useState(false);
  const [videoSelecionado, setVideoSelecionado] = useState(null)
  const [search, setSearch] = useState('')
  const { app: { videosTutoriais, modoOffline } } = useContext(AppContext);

  function handleClose() {
    setOpen(false);
    setVideoSelecionado(null)
  }

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
      setSearch('')
    },
  }));

  useEffect(() => {
    if(open) {
      if(videosTutoriais) {
        if(videosTutoriais.length > 0) {
          setVideoSelecionado(videosTutoriais[0])
        }
      }
    }
  }, [open])

  const searchNormalizado = search.trim().toLocaleLowerCase()

  const videosTutoriais2 = videosTutoriais ? videosTutoriais : []

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        fullScreen
      >
        <DialogTitle id="alert-dialog-title" 
          style={{ color: 'white', backgroundColor: modoOffline ? SECONDARY_COLOR_OFFLINE : SECONDARY_COLOR, textAlign: 'center' }}
          onClose={() => handleClose()}
        >
          TUTORIAIS
        </DialogTitle>
        <DialogContent style={{
          margin: '0',
          padding: '10px'
        }}>
          <div style={{
            display: 'flex',
          }}>
          { videoSelecionado ? 
            <div style={{ display: 'flex', flexDirection: 'column', width: '70%',  }}>
              
              <iframe 
                src={videoSelecionado.link}
                width="100%" 
                height="564" 
                frameborder="0" 
                allow="autoplay; fullscreen" 
                allowfullscreen
              />
              <p style={{ textAlign: 'center', fontSize: '20px', wordBreak: 'break-all', marginTop: '20px' }}>
                <span style={{ fontWeight: 'bold' }}>{videoSelecionado.nome}</span>
                <span> - </span>
                <span>{videoSelecionado.descricao}</span>
              </p>
            </div>
            : 
            <div 
              style={{ 
                width: '70%', 
                background: 'url(/background.jpg)',
                backgroundSize: 'contain', 
                backgroundRepeat: 'no-repeat',
                height: '564px'
              }}
            >
            </div>
          }
            <div style={{ width: '30%', padding: '0px 0px 0px 10px' }}>
              <Paper elevation={3} >
                <List 
                  style={{ maxHeight: '75vh', overflowY: 'auto' }}
                  subheader={
                    <div style={{
                      padding: '0px 5px'
                    }}>
                      <TextInput 
                        handleEnter={() => {}}
                        label='Filtrar'
                        handleKey={() => {}}
                        value={search}
                        onChange={(value) => setSearch(value.target.value)}
                        fullWidth
                        autoFocus
                      />
                    </div>
                  }
                >
                  <Divider />
                  {videosTutoriais2.filter((item) => {
                    if(item.nome_normalizado.includes(searchNormalizado)) return true
                    return item.descricao_normalizado.includes(searchNormalizado)
                  }).map((item) =>
                    <>
                      <ListItem button onClick={() => setVideoSelecionado(item)}>
                        <ListItemText
                          primary={item.nome}
                          secondary={item.descricao}
                          style={{ wordBreak: 'break-all' }}
                        />
                      </ListItem>
                      <Divider />
                    </>
                  )}
                </List>
              </Paper>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
});
  
export default DialogoConfirmacao;
  