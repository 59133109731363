import React, { forwardRef, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { useDebounce } from 'use-debounce';

import AutoCompleteProduto from './Template';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    opacity: '0.75',
  },
  root2: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    opacity: '0.75',
    border: '1ps solid black'
  },
  iconButton: {
    padding: 10,
    color: theme.palette.primary.main,
  },
}));

const Search = forwardRef(
  (
    {
      label,
      value,
      onChange,
      searchHandle,
      searchHandle2 = () => {},
      handleKey = () => {},
      filters = { ativo: true },
      resource = 'produtos/search/autocomplete',
      isPaper = true
    },
    forwardedRef,
  ) => {
    const classes = useStyles();
    const [valueDebounce] = useDebounce(value, 500);
    const [produto, setProduto] = useState(null);

    function isNumber(valor) {
      if (
        valor === '0'
        || valor === '1'
        || valor === '2'
        || valor === '3'
        || valor === '4'
        || valor === '5'
        || valor === '6'
        || valor === '7'
        || valor === '8'
        || valor === '9'
      ) {
        return true;
      }
      return false;
    }

    function contemNumber() {
      if (value.length > 0) {
        if (isNumber(value[0])) return true;
        return false;
      }
      return false;
    }

    function submitProduto() {
      if (contemNumber()) {
        searchHandle(value, 0);
        setProduto(null);
      } else {
        if (produto) {
          searchHandle(produto.codigo, produto.id);
        } else {
          searchHandle('', 0);
        }
        setProduto(null);
      }
    }

    function submitProduto2() {
      if (contemNumber()) {
        //searchHandle2(value, 0);
        //setProduto(null);
      } else {
        if (produto) {
          searchHandle2(produto.codigo, produto.id);
        } else {
          searchHandle2('', 0);
        }
        setProduto(null);
      }
    }

    function keyEvent(keyCode, keyName) {
      if (keyCode === 13) {
        submitProduto();
      } else if (keyCode === 16) {
        submitProduto2();
      } else {
        handleKey(keyCode, keyName);
      }
    }

    return (
      <>
        {isPaper ? <Paper
          component="form"
          className={classes.root}
          onSubmit={(e) => e.preventDefault()}
        >
          <>
            <AutoCompleteProduto
              inputValue={value}
              onChange={(produtoNew) => setProduto(produtoNew)}
              updateValue={(newValue) => onChange(newValue)}
              value={produto}
              label={label}
              valueDebounce={valueDebounce}
              resource={resource}
              nested={['unidade']}
              handleKey={keyEvent}
              ref={forwardedRef}
              contemNumber={contemNumber()}
              filters={filters}
            />
            <IconButton
              className={classes.iconButton}
              aria-label="search"
              onClick={() => submitProduto()}
              tabindex="-1"
            >
              <SearchIcon />
            </IconButton>
          </>
        </Paper> :
        <div
          style={{
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            opacity: '0.75',
            border: '1px solid black'
          }}
          //className={classes.root2}
        >
          <>
            <AutoCompleteProduto
              inputValue={value}
              onChange={(produtoNew) => setProduto(produtoNew)}
              updateValue={(newValue) => onChange(newValue)}
              value={produto}
              label={label}
              valueDebounce={valueDebounce}
              resource={resource}
              nested={['unidade']}
              handleKey={keyEvent}
              ref={forwardedRef}
              contemNumber={contemNumber()}
              filters={filters}
            />
            <IconButton
              className={classes.iconButton}
              aria-label="search"
              onClick={() => submitProduto()}
              tabindex="-1"
            >
              <SearchIcon />
            </IconButton>
          </>
        </div>
        }
      </>
    );
  },
);

export default Search;
