import { createMuiTheme } from '@material-ui/core/styles';

import {
  PRIMARY_COLOR_OFFLINE, 
  SECONDARY_COLOR_OFFLINE
} from '../utils';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR_OFFLINE,
    },
    secondary: {
      main: SECONDARY_COLOR_OFFLINE,
    },
  },
  overrides: {
    MuiAutocomplete: {
      option: {
        '&[data-focus="true"]': {
          backgroundColor: SECONDARY_COLOR_OFFLINE,
          color: 'white',
        },
      },
    },
  },
});

export default theme;
