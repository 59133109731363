import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
  useContext
} from 'react';

import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, makeStyles,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { api } from '../../../services';
import {
  SECONDARY_COLOR, ATALHO_AVANCAR, ATALHO_VOLTAR, STATUS_ATIVO, SECONDARY_COLOR_OFFLINE
} from '../../../utils';
import { TextInput, AutoCompleteRemoto } from '../../Inputs';
import { AppContext } from '../../../app/context';

const inputs = [
  {
    nome: 'gerente de caixa',
    defaultValue: null,
    label: 'Responsável pela retirada',
  },
  {
    nome: 'senha',
    defaultValue: '',
    label: 'Senha',
  },
];

const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const ESC_ACTION = 'Escape';

const CONSULTOR_PRECOS_ACTION = 'F3';

const DialogoNota = forwardRef(({
  handleCancelar, handleSalvar, title, enqueueSnackbar, mensagemSucesso = 'Item removido com sucesso!', openConsultorPrecos= () => {}
}, ref) => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [codigo, setCodigo] = useState(0);
  const [dados, setDados] = useState(null);

  const { app: { modoOffline } } = useContext(AppContext);

  const refs = useRef([]);

  refs.current = inputs.map(
    (ref, index) => refs.current[index] = React.createRef(),
  );

  const handleChange = (event) => {
    setPassword(event.target.value);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setPassword('');
    handleCancelar();
  };

  useImperativeHandle(ref, () => ({
    handleOpen(codigoNew = 0, dados = null) {
      setOpen(true);
      setCodigo(codigoNew);
      setUser(null)
      setDados(dados)
    },
  }));

  async function handleSalvarDialog() {
    if (!erroExistente) {
      try {
        const dataConfig = await api.post('/login_retirada_caixa', {
          username: user.username,
          password,
        });
        if (dataConfig.data.status) {
          handleSalvar(dataConfig.data.user_id, dados);
          setOpen(false);
          setPassword('');
          enqueueSnackbar(mensagemSucesso, {
            variant: 'success',
          });
        } else {
          setOpen(false);
          setPassword('');
          enqueueSnackbar('Senha incorreta!', {
            variant: 'error',
          });
        }
      } catch (e) {
        setOpen(false);
        setPassword('');
        enqueueSnackbar('INTERNET: ERRO AO AUTORIZAR', {
          variant: 'error',
        });
      }
    }
  }

  function handleKey(action, name) {
    switch (name) {
      case FECHAR_VENDA_ACTION:
        handleSalvarDialog();
        break;
      case CANCELAR_VENDA_ACTION:
        handleCloseDialog();
        break;
      case ESC_ACTION:
        handleCloseDialog();
        break;
      case CONSULTOR_PRECOS_ACTION:
        openConsultorPrecos();
        break;
      default:
        break;
    }
  }

  function validateUser() {
    let error = '';
    if (!user) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateSenha() {
    let error = '';
    if (!password) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function getErros() {
    const errosOld = [
      '',
      '',
    ];
    errosOld[0] = validateUser();
    errosOld[1] = validateSenha();

    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  function getRefNextInput(index) {
    let position = -1;
    switch (index) {
      case 0:
        position = 1;
        break;
      case 1:
        position = 0;
        break;
      default:
        break;
    }
    return position;
  }

  function handleNextInput(index) {
    const position = getRefNextInput(index);
    if (position !== -1) {
      if (refs.current[position].current) {
        refs.current[position].current.focus();
        refs.current[position].current.select();
      }
    } else {
      handleSalvarDialog();
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="form-dialog-title" style={{ color: 'white', backgroundColor: modoOffline ? SECONDARY_COLOR_OFFLINE : SECONDARY_COLOR, textAlign: 'center' }}>
          {title}
        </DialogTitle>
        <DialogContent>
          <AutoCompleteRemoto
            id="gerente"
            ref={refs.current[0]}
            name={inputs[0].nome}
            label="Responsável pela retirada"
            value={user}
            onChange={(value) => setUser(value)}
            handleKey={handleKey}
            handleEnter={() => handleNextInput(0)}
            resource="users"
            nested={[]}
            toString={false}
            fullwidth
            autoFocus
            campo="username"
            filters={{ is_responsavel: true, status: STATUS_ATIVO }}
            getOptionSelected={(option, value) => option.username === value.username}
            getOptionLabel={(option) => `${option.username}`}
          />
          <TextInput
            ref={refs.current[1]}
            name={inputs[1].nome}
            type={showPassword ? 'text' : 'password'}
            label="Senha"
            value={password}
            onChange={handleChange}
            fullWidth
            handleKey={handleKey}
            handleEnter={() => handleNextInput(1)}
            error={erros[0] !== ''}
            helperText={erros[0]}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
              )}
            labelWidth={70}
          />
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={handleCloseDialog}
            color="secondary"
            variant="contained"
            tabindex="-1"
          >
            {`Cancelar (${CANCELAR_VENDA_ACTION})`}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSalvarDialog}
            disabled={erroExistente}
            tabindex="-1"
          >
            {`Confirmar (${FECHAR_VENDA_ACTION})`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoNota;
