import React, { forwardRef } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';

import { NumberInput } from './NumberInput';

export const WeightInput = forwardRef(({ isGrams, ...rest }, ref) => (
  <NumberInput
    {...rest}
    prefix=""
    decimalPlaces={isGrams ? 0 : 3}
    ref={ref}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">{isGrams ? ' g' : ' Kg'}</InputAdornment>
      ),
    }}
  />
));
