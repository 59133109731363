import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useContext
} from 'react';

import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import {
  TextInput,
} from '../..';
import {
  ATALHO_VOLTAR,
  ATALHO_AVANCAR,
  readCodigoBarras,
  SECONDARY_COLOR,
  SECONDARY_COLOR_OFFLINE
} from '../../../utils';

import { AppContext } from '../../../app/context';

const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const ESC_ACTION = 'Escape';

const CONSULTOR_PRECOS_ACTION = 'F3';

const DialogoConfirmacao = forwardRef(({ handlePedido = () => {}, openConsultorPrecos= () => {} }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [codigo, setCodigo] = useState('');

  const { app: { modoOffline } } = useContext(AppContext);

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
      setCodigo('');
    },
  }));

  function validateCodigo() {
    if (codigo.length !== 13) {
      return 'Este campo deve conter 13 dígitos!';
    }
    return '';
  }

  const erroCodigo = validateCodigo();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSalvar = () => {
    if (erroCodigo !== '') {
      enqueueSnackbar(erroCodigo, {
        variant: 'error',
      });
    } else {
      setOpen(false);
      handlePedido(readCodigoBarras(codigo));
    }
  };

  function limparCodigo(codigo) {
    if (codigo) {
      const codigoSemEspacos = codigo.trim();
      return codigoSemEspacos.replace(/\D/g, '');
    }
    return '';
  }

  function handleActions(key, name) {
    switch (name) {
      case CANCELAR_VENDA_ACTION:
        handleClose();
        break;
      case FECHAR_VENDA_ACTION:
        handleSalvar();
        break;
      case ESC_ACTION:
        handleClose();
        break;
      case CONSULTOR_PRECOS_ACTION:
        openConsultorPrecos();
        break;
      default:
        break;
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle 
          id="alert-dialog-title" 
          style={{ 
            color: 'white', 
            backgroundColor: modoOffline ? SECONDARY_COLOR_OFFLINE :  SECONDARY_COLOR, 
            textAlign: 'center' 
          }}
        >
          CARREGAR PEDIDO
        </DialogTitle>
        <DialogContent>
          <TextInput
            handleEnter={() => handleSalvar()}
            label=""
            handleKey={handleActions}
            value={codigo}
            onChange={(value) => setCodigo(limparCodigo(value.target.value))}
            fullWidth
            style={{ backgroundColor: 'white' }}
            autoFocus
            error={erroCodigo !== ''}
            helperText={erroCodigo}
          />
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button 
            onClick={handleClose} 
            color="secondary" 
            variant="contained"
            tabindex="-1"
          >
            {`Fechar (${CANCELAR_VENDA_ACTION})`}
          </Button>
          <Button
            onClick={handleSalvar}
            color="secondary"
            variant="contained"
            tabindex="-1"
            disabled={erroCodigo !== ''}
          >
            {`Carregar (${FECHAR_VENDA_ACTION})`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoConfirmacao;
