import React, {
  useRef, useContext, useEffect
} from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';

import { makeStyles, Box, Paper } from '@material-ui/core';

import {
  DialogoDeletarComSenha,
  DialogoSenha,
  SearchProduto,
  LabelCaixa,
  LabelSubtotal,
  LabelTeclasAtalho,
  Tabela,
  DialogoConsultorPrecos,
  DialogoConfirmacao,
  DialogoConsultorCliente,
  LabelCaixaria,
  DialogoConsultorClienteCompatibilizacao,
  DialogoSelecionarCliente,
  DialogoConferenciaGrandesPesagens
} from '../../../../components';
import { useItensPreVenda } from '../../../../hooks';
import {
  toStringItem,
  ATALHO_AVANCAR,
  ATALHO_VOLTAR,
  STATUS_ATIVO,
  getNomeProduto,
  TIPO_OFERTA_PRODUTO_LIBERACAO
} from '../../../../utils';
import { VendaContext } from '../../context';
import FormItemVenda from './Form';
import {
  api,
} from '../../../../services';
import DialogoDeletarItem from '../../../../components/Dialogos/DialogoDeletarItem'
import DialogoAutorizacaoComQrcode from '../../../../components/Dialogos/DialogoAutorizacaoComQrcode'
import DialogoCarregarLiberacoes from '../../../CreateVenda/_SubPages/Itens/DialogoCarregarLiberacoes'
import { AppContext } from '../../../../app/context';


const useStyles = makeStyles((theme) => ({
  btn: {
    marginTop: '10px',
    opacity: '0.75',
  },
  header: {
    height: '17%',
  },
  body: {
    height: '83%',
  },
  footer: {
    height: '15vh',
    padding: '10px',
    paddingBottom: '20px',
  },
  headerLivre: {
    height: '17%',
  },
  bodyLivre: {
    height: '83%',
  },
  rootProduto: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50px',
    fontSize: '32px',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    textAlign: 'center',
    opacity: '0.75',
  },
}));

const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const DELETAR_ITEM_ACTION = 'F2';
const CONSULTOR_PRECOS_ACTION = 'F3';
const CONSULTOR_CLIENTE_COMPATIBILIZACAO = 'F10';
const MUDAR_CAIXARIA_ACTION = 'F7';
const ESC_ACTION = 'Escape';
const SELECIONAR_CLIENTE_ACTION = 'F11';
const CARREGAR_PRECOS_LIBERADOS_ACTION = 'F12';
const CONFERENCIA_GRANDES_PESAGENS_ACTION = 'F1';

const FrenteVenda = ({
  irParaTelaInit,
  getSessionId,
}) => {
  const { app: { 
    modoOffline,
    consultarPrecosPermissao,
    tipoCaixa,
    nomeCaixa,
    valorMaximoPesoNoFc,
    conferenciaGrandesPesagensPermissao,
    pesquisaPorCodigoDeBalancaPermissao
  }, openConsultorPrecos } = useContext(AppContext);
  const { venda: { itens, cliente, itensExcluidos }, dispatch } = useContext(VendaContext);
  const refDialogoSenha = useRef(null);
  const refDialogoDeletarItem = useRef(null);
  const refDialogoConsultorCliente = useRef(null);
  const refDialogoDeletar = useRef(null);
  const refDialogoConsultorPrecos = useRef(null);
  const {
    searchHandle,
    searchHandle2,
    addNewItem,
    setSearch,
    produto,
    subTotal,
    refSearch,
    search,
    refSidebarInputs,
    enqueueSnackbar,
    handleFinalizarPreVenda,
    podeFecharVenda,
    changeCaixaria,
    setProduto,
    handleCarregarLiberacoes,
    addItemCgp
  } = useItensPreVenda(itens, updateItem, cliente, getSessionId(), irParaTelaInit, itensExcluidos, addItensLiberacao, modoOffline, pesquisaPorCodigoDeBalancaPermissao);

  const refDialogoConfirmacao = useRef(null);
  const refDialogoConsultorClienteCompatibilizacao = useRef(null);
  const refDialogoAutorizacaoComQrcode = useRef(null);

  const refDialogoSelecionarCliente = useRef(null);
  const refDialogoCarregarLiberacoes = useRef(null);

  const classes = useStyles();

  const nomeProduto = getNomeProduto(produto);

  const refDialogoConferenciasGrandesPesagens = useRef(null);

  useEffect(() => {
    if (refDialogoSelecionarCliente.current) {
      refDialogoSelecionarCliente.current.handleOpen();
    }
  }, []);

  function updateItem(body) {
    dispatch({
      type: 'ADD_ITEM',
      item: body,
    });
    if (refSearch.current) refSearch.current.focus();
  }

  function addItensLiberacao(liberacoes) {
    dispatch({
      type: 'ADD_ITENS_LIBERACOES',
      liberacoes,
    });
    if (refSearch.current) refSearch.current.focus();
  }

  function removeItens(indices) {
    dispatch({ type: 'REMOVE_ITEM', indices });
  }

  function openConfirmacao() {
    if (refDialogoConfirmacao.current) { refDialogoConfirmacao.current.handleOpen('Fechamento da pré venda', 'Tem certeza que deseja finalizar a pré venda!', 0); }
  }

  function handleActions(action) {
    switch (action) {
      case FECHAR_VENDA_ACTION:
        if (podeFecharVenda && itens.length > 0) {
          if (refDialogoConsultorCliente.current) {
            refDialogoConsultorCliente.current.handleOpen();
          }
        }
        break;
      case CANCELAR_VENDA_ACTION:
        //if (refDialogoSenha.current) { refDialogoSenha.current.handleOpen(); }
        if (refDialogoAutorizacaoComQrcode.current) { refDialogoAutorizacaoComQrcode.current.handleOpen(); }
        break;
      case CONSULTOR_PRECOS_ACTION:
        if (consultarPrecosPermissao) {
          if (refDialogoConsultorPrecos.current) {
            refDialogoConsultorPrecos.current.handleOpen();
          }
        }
        break;
      case DELETAR_ITEM_ACTION:
        if (itens.length > 0) {
          //if (refDialogoDeletar.current) { refDialogoDeletar.current.handleOpen(); }
          if (refDialogoDeletarItem.current) { refDialogoDeletarItem.current.handleOpen(); }
        }
        break;
      /*case CONSULTOR_CLIENTE_COMPATIBILIZACAO:
        if (editarClientePermissao) {
          if (refDialogoConsultorClienteCompatibilizacao.current) {
            refDialogoConsultorClienteCompatibilizacao.current.handleOpen();
          }
        }
        break;*/
      case MUDAR_CAIXARIA_ACTION:
        changeCaixaria()
        break;
      case ESC_ACTION:
        /*setProduto(null);
        setSearch('');
        if (refSearch.current) refSearch.current.focus();*/
        //if (refDialogoSenha.current) { refDialogoSenha.current.handleOpen(); }
        if (refDialogoAutorizacaoComQrcode.current) { refDialogoAutorizacaoComQrcode.current.handleOpen(); }
        break;
      case SELECIONAR_CLIENTE_ACTION:
        if (refDialogoSelecionarCliente.current) {
          refDialogoSelecionarCliente.current.handleOpen();
        }
        break;
      case CARREGAR_PRECOS_LIBERADOS_ACTION:
        if(refDialogoCarregarLiberacoes.current) {
          refDialogoCarregarLiberacoes.current.handleOpen()
        }
        break;
      case CONFERENCIA_GRANDES_PESAGENS_ACTION:
        if(conferenciaGrandesPesagensPermissao) {
          if (refDialogoConferenciasGrandesPesagens.current) { refDialogoConferenciasGrandesPesagens.current.handleOpen(); }
        }
        break;
      default:
        break;
    }
  }

  function handleConfirma(codigo) {
    switch (codigo) {
      case 0:
        handleFinalizarPreVenda();
        break;
      default:
        break;
    }
  }

  const isCaixaria = produto ? (produto.unidades_caixa_nivel_1 > 0 || produto.peso_caixa_nivel_1 > 0) : false

  function getLabelsAtalhos() {
    const labelsAtalhos = [];

    labelsAtalhos.push({ atalho: DELETAR_ITEM_ACTION, label: 'Remover item', status: itens.length > 0 });
    if (consultarPrecosPermissao) labelsAtalhos.push({ atalho: CONSULTOR_PRECOS_ACTION, label: 'Cons. Preço' });
    labelsAtalhos.push({ atalho: CANCELAR_VENDA_ACTION, label: 'Voltar' });
    labelsAtalhos.push({ atalho: MUDAR_CAIXARIA_ACTION, label: 'Caixaria', status: isCaixaria });
    labelsAtalhos.push({ atalho: FECHAR_VENDA_ACTION, status: itens.length > 0, label: 'Concluir' });
    //if (editarClientePermissao) labelsAtalhos.push({ atalho: CONSULTOR_CLIENTE_COMPATIBILIZACAO, label: 'Comp. Cliente' });
    labelsAtalhos.push({ atalho: SELECIONAR_CLIENTE_ACTION, label: 'Cliente' });
    labelsAtalhos.push({ atalho: CARREGAR_PRECOS_LIBERADOS_ACTION, label: 'Car. Liber.' });
    labelsAtalhos.push({ atalho: CONFERENCIA_GRANDES_PESAGENS_ACTION, label: 'CGP' });

    if (labelsAtalhos.length <= 4) {
      return [
        labelsAtalhos,
        [],
      ];
    }
    return [
      labelsAtalhos.slice(0, 4),
      labelsAtalhos.slice(4),
    ];
  }

  function interceptadorSearchHandle(codigo, id) {
    searchHandle(codigo, id)
  }

  function montarObjVendaNaoFinalizada() {
    const listaItens = [];

    for (let i = 0; i < itens.length; i += 1) {
      listaItens.push({
        peso: itens[i].peso,
        unidades: itens[i].unidades,
        preco_venda: itens[i].unitario,
        preco_compra: itens[i].produto.precoCompraMedio,
        produto_id: itens[i].produto.id,
        idIndicadorChange: itens[i].idIndicadorChange,
        unidades_caixa: itens[i].unidadesCaixa,
        preco_venda_caixa: itens[i].unitarioCaixa,
        peso_caixa: itens[i].pesoCaixa,
        unidades_caixa_aparente: itens[i].unidadesCaixaAparente,
        nivel_caixaria: itens[i].nivelCaixaria,
      });
    }

    return {
      listaItens,
      data: new Date(),
      cliente_id: cliente ? cliente.id : null,
      sessao_id: getSessionId(),
    };
  }

  async function registraVendaNaoFinalizada() {
    try {
      const objVenda = montarObjVendaNaoFinalizada();
      const data = await api.post('/pre_vendas_excluidas', {
        ...objVenda,
      });
      return data.data.id;
    } catch (e) {
      return -1;
    }
  }

  async function handleCancelar() {
    irParaTelaInit();
    await registraVendaNaoFinalizada()
  }

  function hasLiberacao(itens) {
    let hasLiberacaoValue = false
    for(let i = 0; i < itens.length; i++) {
      if(itens[i].tipo_oferta === TIPO_OFERTA_PRODUTO_LIBERACAO) {
        hasLiberacaoValue = true
      }
    }
    return hasLiberacaoValue
  }

  const hasLiberacaoValue = hasLiberacao(itens)

  return (
    <>
      <Box padding="10px" className={classes.header}>
        <Box margin="0px 0px 10px">
          <Paper className={classes.rootProduto}>{nomeProduto}</Paper>
        </Box>
        <SearchProduto
          label="Pesquisar produto"
          value={search}
          onChange={(e) => setSearch(e)}
          fullwidth
          searchHandle={interceptadorSearchHandle}
          searchHandle2={searchHandle2}
          ref={refSearch}
          handleKey={(keyCode, keyName) => {
            handleActions(keyName);
          }}
          filters={{}}
          //filters={pesquisaPorNomes ? {  status: STATUS_ATIVO } : { is_search: true, status: STATUS_ATIVO }}
          resource = 'produtos/search/autocomplete2'
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        padding="10px"
        className={classes.body}
      >
        <Box
          flex={1.5}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          overflow="auto"
        >
          <Box>
            <LabelCaixa tipoCaixa={tipoCaixa} nomeCaixa={nomeCaixa}/>
            <Box mt="15px">
              <LabelSubtotal valor={subTotal} />
            </Box>
          </Box>
          { (produto) ? (
            <Box mt="15px">
              <LabelCaixaria produto={produto} />
            </Box>
          ): null}
          <LabelTeclasAtalho
            fontSize="13px"
            grid={getLabelsAtalhos()}
          />
        </Box>
        <Box padding="0 10px" flex={4}>
          <Tabela
            rows={toStringItem(itens)}
            headCells={[
              /*{
                field: 'numeroItem', label: 'Cód.',
              },*/
              {
                field: 'numeroItem', label: 'Cód.', handleColor: (row) => {
                  if(row.tipo_oferta === TIPO_OFERTA_PRODUTO_LIBERACAO) {
                    return 'red'
                  }
                  return 'black'
                }
              },
              {
                field: 'produto',
                label: 'Produto',
              },
              {
                field: 'peso', right: true, label: 'Peso (Kg)',
              },
              {
                field: 'unitario', right: true, label: 'Unitário',
              },
              {
                field: 'unidades', right: true, label: 'Caixaria',
              },
              {
                field: 'unidades', right: true, label: 'R$ / Caixa',
              },
              {
                field: 'unidades', right: true, label: 'Qtde',
              },
              {
                field: 'total', right: true, label: 'SubTotal',
              },
            ]}
          />
        </Box>
        <Box
          flex={1.5}
          display="flex"
          flexDirection="column"
          overflow="auto"
        >
          <FormItemVenda
            handleKey={(keyCode, keyName) => {
              handleActions(keyName);
            }}
            handleNewItem={addNewItem}
            disabledSubmit={produto === null}
            ref={refSidebarInputs}
            editPrice={false}
            produto={produto}
            tipoTransacao="venda"
            handleNewItemCodigo={(dados) => {
              const {codigo, ...body} = dados
              addNewItem(body)
              searchHandle(codigo)
            }}
            valorMaximoPesoNoFc={valorMaximoPesoNoFc}
          />
        </Box>
      </Box>
      <DialogoConfirmacao ref={refDialogoConfirmacao} handleConfirma={handleConfirma} isInput openConsultorPrecos={openConsultorPrecos}/>
      <DialogoSenha
        ref={refDialogoSenha}
        handleClose={() => {}}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={() => {
          handleCancelar()
        }}
        title="Autorização cancelamento de pedido!"
        openConsultorPrecos={openConsultorPrecos}
      />
      <DialogoDeletarComSenha
        ref={refDialogoDeletar}
        itens={itens}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={(numeroItem) => {
          removeItens([itens[numeroItem - 1].uidd]);
        }}
        title="Autorização para remover item!"
        openConsultorPrecos={openConsultorPrecos}
      />
      <DialogoConsultorPrecos ref={refDialogoConsultorPrecos} openConsultorPrecos={openConsultorPrecos}/>
      <DialogoConsultorCliente
        ref={refDialogoConsultorCliente}
        handleSelecionado={() => {
          openConfirmacao();
        }}
        handleFechado={() => {}}
        permiteClientePadrao={false}
        openConsultorPrecos={openConsultorPrecos}
      />
      <DialogoDeletarItem
        ref={refDialogoDeletarItem}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={(codigo) => {
          if(codigo.length >= 6) {
            const index = itens.findLastIndex(item => {
              let codigoEfetivo = ''
              switch (item.nivelCaixaria) {
                case 0:
                  codigoEfetivo = item.produto.codigo
                  break;
                case 1:
                  codigoEfetivo = item.produto.codigo_caixa_nivel_1
                  break;
                case 2:
                  codigoEfetivo = item.produto.codigo_caixa_nivel_2
                  break;
                case 3:
                  codigoEfetivo = item.produto.codigo_caixa_nivel_3
                  break;
                default:
                  codigoEfetivo = item.produto.codigo
                  break;
              }
              return codigoEfetivo === codigo
            })
            if(index !== undefined) {
              removeItens([itens[index].uidd]);
            }
          } else {
            const position = parseInt(codigo, 10) - 1
            if(position <= itens.length - 1) {
              removeItens([itens[position].uidd]);
              enqueueSnackbar('Item removido com sucesso!', {
                variant: 'success',
              });
            } else {
              enqueueSnackbar('Não existe um item com este código!', {
                variant: 'error',
              });
            }
          }
        }}
        title="Remover Item!"
        mensagemRemovido="Item Removido com Sucesso!"
        titleAutorizacao="Autorizar remoção de item"
        mensagemRemovidoAutorizacao="Autorizada a remoção do item!"
        openConsultorPrecos={openConsultorPrecos}
      />
      <KeyboardEventHandler
        handleKeys={[
          FECHAR_VENDA_ACTION,
          CANCELAR_VENDA_ACTION,
          DELETAR_ITEM_ACTION,
          CONSULTOR_PRECOS_ACTION,
          //CONSULTOR_CLIENTE_COMPATIBILIZACAO,
          MUDAR_CAIXARIA_ACTION
        ]}
        onKeyEvent={(key) => handleActions(key)}
      />
      <DialogoConsultorClienteCompatibilizacao
        handleSelecionado={() => {}}
        handleFechado={() => {}}
        ref={refDialogoConsultorClienteCompatibilizacao}
        openConsultorPrecos={openConsultorPrecos}
      />
      <DialogoAutorizacaoComQrcode
        ref={refDialogoAutorizacaoComQrcode}
        handleClose={() => {}}
        enqueueSnackbar={enqueueSnackbar}
        handleSalvar={(user_id) => {
          handleCancelar()
        }}
        mensagemSucesso={'Pedido cancelado com sucesso!'}
        title="Autorização cancelamento de pedido!"
        openConsultorPrecos={openConsultorPrecos}
      />
      <DialogoSelecionarCliente
        handleSelecionado={() => {}}
        handleTerminado={() => {
          if (refSearch.current) refSearch.current.focus();
        }}
        handleFechado={(cliente) => {
          if (refSearch.current) refSearch.current.focus();
          if(cliente) {
            dispatch({ type: 'UPDATE_CLIENTE', cliente });
          }
        }}
        dispatch={dispatch}
        ref={refDialogoSelecionarCliente}
        clienteInicial={cliente}
        hasLiberacaoValue={hasLiberacaoValue}
        incluirAnonimo={false}
        openConsultorPrecos={openConsultorPrecos}
      />
      <DialogoCarregarLiberacoes 
        ref={refDialogoCarregarLiberacoes} 
        handleCarregarLiberacoes={handleCarregarLiberacoes} 
        cliente={cliente}
        openConsultorPrecos={openConsultorPrecos}
      />
      <DialogoConferenciaGrandesPesagens 
        ref={refDialogoConferenciasGrandesPesagens} 
        handleSalvar2={addItemCgp}
        openConsultorPrecos={openConsultorPrecos}
      />
    </>
  );
};

export default FrenteVenda;
