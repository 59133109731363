import React, {
  useRef, forwardRef, useImperativeHandle, useState, useEffect,
} from 'react';

import {
  Paper, Box, Button,
} from '@material-ui/core';

import {
  IntegerInput,
  PesoInput,
  PrecoInput,
} from '../../../../components/Inputs';
import {
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL,
  formatMoeda,
  getPrecoUnitarioVerdadeiroCaixa,
  getTotal2,
  pesoEfetivo,
  getValorCompraMedioImposto,
  formatPeso
} from '../../../../utils';
import { useDebounce } from 'use-debounce';
import { useSnackbar } from 'notistack';

const FormItemVenda = forwardRef(({
  handleNewItem,
  handleKey = () => {},
  disabledSubmit = false,
  produto,
  handleNewItemCodigo,
  valorMaximoPesoNoFc
}, ref) => {
  const [unidades, setUnidades] = useState(0);
  const [peso, setPeso] = useState(0);
  const [unitario, setUnitario] = useState(0);
  const [erros, setErros] = useState(['', '', '']);
  const [unidadesDebounce] = useDebounce(unidades, 200);
  const { enqueueSnackbar } = useSnackbar();

  const refs = useRef([]);
  const refButton = useRef(null);
  const inputs = [
    {
      nome: 'unidades',
      defaultValue: 0,
      label: 'Quantidade',
    },
    {
      nome: 'peso',
      defaultValue: 0,
      label: 'Peso',
    },
    {
      nome: 'unitario',
      defaultValue: 0,
      label: 'Preço Unitário',
    },
  ];

  refs.current = inputs.map(
    (ref, index) => refs.current[index] = React.createRef(),
  );

  useEffect(() => {
    if (produto) {
      setUnitario(getPrecoUnitarioVerdadeiroCaixa(produto, unidades, peso));
    }
  }, [unidades, peso, produto]);

  useEffect(() => {
    if (produto) {
      setErros(['', '', '']);
      if(produto.unidade.tipo !== POR_PESO_VARIAVEL_SEM_UNIDADE || produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE && produto.nivelCaixa) {
        setUnidades(Number.NaN);
      } else {
        setUnidades(inputs[0].defaultValue);
      }
      setPeso(inputs[1].defaultValue);
      setUnitario(getPrecoUnitarioVerdadeiroCaixa(produto, unidades, peso));
    }
  }, [produto]);

  useEffect(() => {
    setPeso(pesoEfetivo(produto, unidades, peso));
  }, [unidades, produto]);

  function hideUnidades() {
    return produto ? produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE && !produto.nivelCaixa : true;
  }

  function hidePeso() {
    return produto ? produto.unidade.tipo === POR_UNIDADE : true;
  }

  function hideUnitario() {
    return produto === null;
  }

  function disabledPeso() {
    return produto ? produto.unidade.tipo === POR_PESO_FIXO || (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE && produto.nivelCaixa) : true;
  }

  function disabledUnitario() {
    return true
  }

  function validateUnidades() {
    let error = '';
    const condicao = produto ? produto.unidade.tipo !== POR_PESO_VARIAVEL_SEM_UNIDADE || (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE && produto.nivelCaixa) : false;
    if (condicao) {
      if (unidades <= 0 || Number.isNaN(unidades)) {
        error = 'Este campo é obrigatório';
      } else if (unidades > 100000) {
        error = 'Valor muito alto!';
      }
    }
    return error;
  }

  function validatePeso() {
    let error = '';
    const condicao = produto ? produto.unidade.tipo === POR_PESO_VARIAVEL || produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE && !produto.nivelCaixa : false;
    if (condicao) {
      if (peso <= 0 || Number.isNaN(peso)) {
        error = 'Este campo é obrigatório';
      } else if (peso > 1000000) {
        error = 'Valor muito alto!';
      } else if(peso > valorMaximoPesoNoFc) {
        error = `O peso não pode ser maior que o valor máximo ${formatPeso(valorMaximoPesoNoFc)}`;
      }
    }
    return error;
  }

  function validateUnitario() {
    let error = '';
    if (unitario <= 0 || Number.isNaN(unitario)) {
      error = 'Este campo é obrigatório';
    } else {
      const precoCompraMedioImpostos = getValorCompraMedioImposto(produto);
      if (unitario < precoCompraMedioImpostos) {
        error = `Preço abaixo do mínimo (${formatMoeda(precoCompraMedioImpostos)})`;
      }
    }
    return error;
  }

  function getErros() {
    const errosOld = erros.slice();
    errosOld[0] = validateUnidades();
    errosOld[1] = validatePeso();
    errosOld[2] = validateUnitario();
    return errosOld;
  }

  useEffect(() => {
    setErros(getErros());
  }, [unidades, peso, unitario]);

  function resetValues() {
    setErros(['', '', '', '']);
    setUnidades(inputs[0].defaultValue);
    setPeso(inputs[1].defaultValue);
    setUnitario(inputs[2].defaultValue);
  }

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (i === 2) {
        if (erros[i] === 'Este campo é obrigatório') return true;
      } else if (erros[i] !== '') return true;
    }
    return false;
  }

  const totalItem = getTotal2(peso, unidades, unitario, produto);

  function handleSubmit() {
    if (!hasError()) {
      handleNewItem({
        unidades,
        peso,
        unitario,
        total: totalItem,
      });
      resetValues();
    }
  }

  useImperativeHandle(ref, () => ({
    focus(index = 0) {
      if (refs.current[index].current) {
        refs.current[index].current.focus();
        refs.current[index].current.select();
      }
    },
    submit() {
      handleSubmit();
    },
    redefineValues(unidadesNew, pesoNew) {
      setUnidades(unidadesNew);
      setPeso(pesoNew);
    },
  }));

  function getRefNextInput(index) {
    let position = -1;
    switch (index) {
      case 0:
        if (!hidePeso() && !disabledPeso()) position = 1;
        else if (!disabledUnitario()) position = 2;
        break;
      case 1:
        if (!disabledUnitario()) position = 2;
        break;
      default:
        break;
    }
    return position;
  }

  function handleNextInput(index) {
    const position = getRefNextInput(index);
    if (position === -1) {
      if (refButton.current && !disabledSubmit) {
        refButton.current.click();
      }
    } else if (refs.current[position].current) {
      refs.current[position].current.focus();
      refs.current[position].current.select();
    }
  }

  const hasUnidade = produto ? produto.unidade.tipo !== POR_PESO_VARIAVEL_SEM_UNIDADE || produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE && produto.nivelCaixa : false;

  function getDadosCertos(valor1, valor2) {
    const valor1String = `${valor1}`
    const codigo = `${valor2}`.substring(valor1String.length)
    return {
      unidades: valor1,
      codigo
    }
  }

  return (
    <Paper elevation={3} style={{ opacity: '0.75' }}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        padding={disabledSubmit ? '0px' : '15px'}
        overflow="auto"
      >
        {!hideUnidades() && (
        <IntegerInput
          name={inputs[0].nome}
          ref={refs.current[0]}
          handleEnter={() => {
            if(hasUnidade) {
              if(!Number.isNaN(unidadesDebounce)) {
                if(unidadesDebounce < 100000) {
                  if(unidades > 100000) {
                    const { unidades: unidades2, codigo} = getDadosCertos(unidadesDebounce, unidades)
                    const unidadesEfetivaValue = unidades2 <= 0 ? 1 : unidades2
                    const pesoEfetivoValue = pesoEfetivo(produto, unidadesEfetivaValue, peso)
                    const total2Value = getTotal2(peso, unidadesEfetivaValue, unitario, produto)

                    if(!(Number.isNaN(unidadesEfetivaValue) || Number.isNaN(pesoEfetivoValue) || Number.isNaN(total2Value))) {
                      handleNewItemCodigo({
                        unidades: unidadesEfetivaValue,
                        peso: pesoEfetivoValue,
                        unitario,
                        total: total2Value,
                        codigo
                      });
                      resetValues();
                    } else {
                      setUnidades(Number.NaN)
                      enqueueSnackbar('Erro inexperado tipo 1, tente novamente!', {
                        variant: 'error',
                      });
                    }
                  } else {
                    handleNextInput(0)
                  }
                } else {
                  handleNextInput(0)
                }
              } else {
                if(unidades > 100000) {
                  const pesoEfetivoValue = pesoEfetivo(produto, 1, peso)
                  const total2Value = getTotal2(peso, 1, unitario, produto)

                  if(!(Number.isNaN(pesoEfetivoValue) || Number.isNaN(total2Value))) {
                    handleNewItemCodigo({
                      unidades: 1,
                      peso: pesoEfetivoValue,
                      unitario,
                      total: total2Value,
                      codigo: `${unidades}`
                    });
                    resetValues();
                  } else {
                    setUnidades(Number.NaN)
                    enqueueSnackbar('Erro inexperado tipo 2, tente novamente!', {
                      variant: 'error',
                    });
                  }
                } else {
                  handleNextInput(0)
                }
              }
            } else {
              handleNextInput(0)
            }
          }}
          label={inputs[0].label}
          handleKey={handleKey}
          value={Number.isNaN(unidades) ? '' : String(unidades)}
          onChange={(value) => {
            const unidadesNew = parseInt(value.target.value, 10)
            setUnidades(unidadesNew)
          }}
          error={erros[0] !== ''}
          helperText={erros[0]}
        />
        )}
        {!hidePeso() && (
        <PesoInput
          name={inputs[1].nome}
          ref={refs.current[1]}
          handleEnter={() => handleNextInput(1)}
          label={inputs[1].label}
          handleKey={handleKey}
          value={Number.isNaN(peso) ? '' : String(peso)}
          onChange={(value) => setPeso(parseFloat(value.target.value))}
          error={erros[1] !== ''}
          helperText={erros[1]}
          disabled={disabledPeso()}
        />
        )}
        {!hideUnitario() && (
        <PrecoInput
          name={inputs[2].nome}
          ref={refs.current[2]}
          handleEnter={() => handleNextInput(2)}
          label={inputs[2].label}
          handleKey={handleKey}
          value={Number.isNaN(unitario) ? '' : String(unitario)}
          onChange={(value) => setUnitario(parseFloat(value.target.value))}
          error={erros[2] === 'Este campo é obrigatório'}
          helperText={`${erros[2]}`}
          disabled={disabledUnitario()}
        />
        )}
        {!(produto === null) && (
        <PrecoInput
          name="total"
          handleEnter={() => {}}
          label="Total"
          handleKey={handleKey}
          value={Number.isNaN(totalItem) ? '' : String(totalItem)}
          onChange={() => {}}
          disabled
        />
        )}
        {!disabledSubmit && (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          ref={refButton}
          disabled={hasError()}
        >
          Adicionar à lista
        </Button>
        )}
      </Box>
    </Paper>
  );
});

export default FormItemVenda;
