import React, {
  forwardRef,
  useEffect,
  useRef,
  useImperativeHandle,
} from 'react';

import InputBase from '@material-ui/core/InputBase';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { getListAPI } from '../../../services';
import { STATUS_ATIVO } from '../../../utils/constants';

import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => createStyles({
  rootInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
    padding: '10px',
  },
  input: {
    padding: '0px 10px',
  },
}));

const AutoCompleteProdutos = forwardRef(
  (
    {
      value,
      onChange,
      inputValue,
      updateValue,
      valueDebounce,
      label,
      handleKey,
      resource,
      nested,
      contemNumber,
      filters,
      ...rest
    },
    forwardedRef,
  ) => {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const refAutoComplete = useRef(null);

    useImperativeHandle(forwardedRef, () => ({
      focus() {
        if (refAutoComplete.current) {
          refAutoComplete.current.focus();
        }
      },
    }));

    useEffect(() => {
      (async () => {
        if (open) {
          try {
            const produtos = await getListAPI(
              resource,
              ['id', 'asc'],
              [1, 10],
              { nome: valueDebounce, status: STATUS_ATIVO, ...filters },
              nested,
            );
            setOptions(produtos.data);
          } catch(e) {
            setOptions([]);
            enqueueSnackbar(
              `INTERNET: ERRO AO BUSCAR DADOS NESSA BARRA DE PESQUISA!`,
              {
                variant: 'error',
              },
            );
          }
        } else {
          setOptions([]);
        }
      })();
    }, [open]);

    useEffect(() => {
      (async () => {
        try {
          const produtos = await getListAPI(
            resource,
            ['id', 'asc'],
            [1, 10],
            {
              nome: valueDebounce, is_kit: false, is_caixa: false, status: STATUS_ATIVO, ...filters,
            },
            nested,
          );
          setOptions(produtos.data);
        } catch(e) {
          setOptions([]);
        }
      })();
    }, [valueDebounce]);

    return (
      <Autocomplete
        open={open && !contemNumber}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        value={value}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          updateValue(newInputValue);
        }}
        getOptionSelected={(option, value) => option.nome === value.nome}
        getOptionLabel={(option) => option.nome}
        options={options}
        loading={loading}
        fullWidth
        autoFocus
        className={classes.input}
        renderInput={(params) => (
          <InputBase
            {...rest}
            {...params}
            color="secondary"
            className={{
              root: classes.rootInput,
              input: classes.input,
            }}
            placeholder={label}
            {...params.inputProps}
            ref={params.InputProps.ref}
            inputRef={refAutoComplete}
            autoFocus
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                if (!open || contemNumber) handleKey(e.keyCode, e.key);
                setOpen(false);
              } else {
                handleKey(e.keyCode, e.key);
              }
            }}
          />
        )}
      />
    );
  },
);

export default AutoCompleteProdutos;
