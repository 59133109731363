import React, { forwardRef } from 'react';

import { TextInput } from '../TextInput';

export const NumberInput = forwardRef(
  (
    {
      value,
      onChange,
      decimalPlaces,
      handleEnter = () => {},
      handleKey = () => {},
      prefix = '',
      name,
      ...rest
    },
    ref,
  ) => {
    const handleChange = (event) => {
      const newValue = event.target.value;
      onChange({ target: { name, value: sanitize(newValue) } });
    };

    function getZeros(count) {
      let zeros = '';
      for (let i = 0; i < count; i++) {
        zeros += '0';
      }
      return zeros;
    }

    function sanitize(value) {
      let replaceQuantity = formatNumberMask(value);
      replaceQuantity = replaceQuantity.replace(/[^0-9.,]/g, '');
      replaceQuantity = replaceQuantity.replace(/[.]/g, '');
      replaceQuantity = replaceQuantity.replace(/,/g, '.');
      const vl = Number(replaceQuantity);
      return vl
    }

    function formatNumberMask(value) {
      let replaceQuantity = value;
      if (value === '') {
        if (decimalPlaces <= 0) {
          return '0';
        }
        return `0,${getZeros(decimalPlaces)}`;
      }
      replaceQuantity = String(Number(value.replace(/[^0-9]/g, '')));
      if (decimalPlaces > 0) {
        if (Number.isNaN(Number(replaceQuantity))) {
          return `0,${getZeros(decimalPlaces)}`;
        }
        if (replaceQuantity.length <= decimalPlaces) {
          return `0,${getZeros(
            decimalPlaces - replaceQuantity.length,
          )}${replaceQuantity}`;
        }
        if (
          replaceQuantity.length > decimalPlaces &&
          replaceQuantity.length < 3 + decimalPlaces
        ) {
          replaceQuantity = `${replaceQuantity.slice(
            0,
            -1 * decimalPlaces,
          )},${replaceQuantity.slice(-1 * decimalPlaces)}`;
        } else {
          const before = replaceQuantity.slice(0, -1 * decimalPlaces);
          const after = replaceQuantity.slice(-1 * decimalPlaces);
          let formated = '';
          for (let i = 0; i < before.length; i += 1) {
            let dot = '';
            if (i % 3 === 0 && i !== 0) dot = '.';
            formated = before[before.length - 1 - i] + dot + formated;
          }
          replaceQuantity = `${formated},${after}`;
        }
      } else if (Number.isNaN(Number(replaceQuantity))) {
        replaceQuantity = '0';
      } else {
        let formated = '';
        for (let i = 0; i < replaceQuantity.length; i += 1) {
          let dot = '';
          if (i % 3 === 0 && i !== 0) dot = '.';
          formated =
            replaceQuantity[replaceQuantity.length - 1 - i] + dot + formated;
        }
        replaceQuantity = formated;
      }
      return replaceQuantity;
    }

    function formatZero(value) {
      const valor = String(value)
      const valor2 = valor.split('.')

      if(valor2.length === 0) {
        return '0.000'
      }

      if(valor2.length === 1) {
        return `${valor2[0]}.000`
      }

      if(valor2.length === 2) {
        if(valor2[1].length === 0) {
          return `${valor2[0]}.000`
        }

        if(valor2[1].length === 1) {
          return `${valor2[0]}.${valor2[1]}00`
        }

        if(valor2[1].length === 2) {
          return `${valor2[0]}.${valor2[1]}0`
        }

        if(valor2[1].length === 3) {
          return `${valor2[0]}.${valor2[1]}`
        }

        return `${valor2[0]}.${valor2[1]}`
      }
    }

    const valueFormated = `${prefix}${formatNumberMask(
      decimalPlaces <= 0
        ? String(value)
        : formatZero(value),
    )}`;

    return (
      <TextInput
        {...rest}
        value={valueFormated}
        onChange={handleChange}
        handleEnter={handleEnter}
        handleKey={handleKey}
        ref={ref}
      />
    );
  },
);
