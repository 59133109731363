export function validaCpf(cpf) {
    const digito1 = parseInt(cpf[0], 10)
    const digito2 = parseInt(cpf[1], 10)
    const digito3 = parseInt(cpf[2], 10)
    const digito4 = parseInt(cpf[3], 10)
    const digito5 = parseInt(cpf[4], 10)
    const digito6 = parseInt(cpf[5], 10)
    const digito7 = parseInt(cpf[6], 10)
    const digito8 = parseInt(cpf[7], 10)
    const digito9 = parseInt(cpf[8], 10)
    const digito10 = parseInt(cpf[9], 10)
    const digito11 = parseInt(cpf[10], 10)

    let digito10Calculado = (
        digito1 * 10 +
        digito2 * 9 +
        digito3 * 8 +
        digito4 * 7 +
        digito5 * 6 +
        digito6 * 5 +
        digito7 * 4 +
        digito8 * 3 +
        digito9 * 2
    )

    digito10Calculado = (digito10Calculado * 10) % 11

    if(digito10 !== digito10Calculado) return 'CPF inválido'

    let digito11Calculado = (
        digito1 * 11 +
        digito2 * 10 +
        digito3 * 9 +
        digito4 * 8 +
        digito5 * 7 +
        digito6 * 6 +
        digito7 * 5 +
        digito8 * 4 +
        digito9 * 3 +
        digito10 * 2
    )

    digito11Calculado = (digito11Calculado * 10) % 11

    if(digito11 !== digito11Calculado) return 'CPF inválido'

    return ''
}
