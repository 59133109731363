import axios from 'axios';

import {
  URL_SERVIDOR_IMPRESSORA,
} from '../utils';

const api = axios.create({
  baseURL: URL_SERVIDOR_IMPRESSORA,
});

async function imprimir(id, type, offline = false) {
  try {
    const url = `/imprimir-document/${id}/${type}`;
    await api.post(url, {
      url_api: offline ? URL_SERVIDOR_IMPRESSORA : process.env.REACT_APP_HOST_API,
    });
    return 1;
  } catch (e) {
    return -1;
  }
}

export async function imprimirNotaVenda(id, offline = false) {
  return await imprimir(id, 1, offline);
}

export async function imprimirNotaRetirada(id, offline = false) {
  if(!offline) {
    return await imprimir(id, 6);
  }

  return -1
}

export async function imprimirRelatorioCaixa(id, offline = false) {
  if(!offline) {
    return await imprimir(id, 3);
  }

  return -1
}

export async function imprimirNotaPreVenda(id, offline = false) {
  return await imprimir(id, 4, offline);
}

export async function imprimirNotaAbertura(id, offline = false) {
  if(!offline) {
    return await imprimir(id, 6);
  }

  return -1
}

export async function imprimirPacote(id, offline = false) {
  if(!offline) {
    return await imprimir(id, 6);
  }

  return -1
}

export async function imprimirRelatorioAberturaCaixa(id, offline = false) {
  return await imprimir(id, 5, offline);
}

export async function imprimirTroco(id, offline = false) {
  return await imprimir(id, 12, offline);
}
