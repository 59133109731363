import React, {
  useContext, forwardRef, useImperativeHandle,
} from 'react';

import Chip from '@material-ui/core/Chip';
import SyncIcon from '@material-ui/icons/Sync';

import { AppContext } from '../../app/context';
import { PRIMARY_COLOR, APP_VERSION, PRIMARY_COLOR_OFFLINE } from '../../utils';
import SignalWifi4BarIcon from '@material-ui/icons/SignalWifi4Bar';
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';

const MenuMaisOpcies = forwardRef(({ saldoIndicadorRetirada }, ref) => {
  const {
    app: { saldoCaixa, modoOffline },
  } = useContext(AppContext);

  useImperativeHandle(ref, () => ({
    open() {
    },
  }));

  const versao = `Versão: ${APP_VERSION}`;

  const isSaldoAcimaLimiteRetirada = (saldoCaixa >= saldoIndicadorRetirada);

  return (
    <div>
      <div style={{ position: 'fixed', top: '10px', right: '20px' }}>
        <Chip
          icon={<SyncIcon style={{
            color: modoOffline ? PRIMARY_COLOR_OFFLINE : PRIMARY_COLOR,
          }}/>}
          label={versao}
          variant="outlined"
          style={{
            color: modoOffline ? PRIMARY_COLOR_OFFLINE : PRIMARY_COLOR,
            borderColor: modoOffline ? PRIMARY_COLOR_OFFLINE : PRIMARY_COLOR,
          }}
          className={modoOffline ? isSaldoAcimaLimiteRetirada ? 'btnSinalizadorRetiradaOffline' : '' : isSaldoAcimaLimiteRetirada ? 'btnSinalizadorRetirada' : ''}
          onDelete={() => {}}
          deleteIcon={modoOffline ? <SignalWifiOffIcon style={{ color: PRIMARY_COLOR_OFFLINE }}/> : <SignalWifi4BarIcon style={{ color: PRIMARY_COLOR }}/>}
        />
      </div>
    </div>
  );
});

export default MenuMaisOpcies;
