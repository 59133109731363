import React, { useState, useEffect } from 'react';

import moment from 'moment';

import { api } from '../../../services'

const Temporizador = ({ enqueueSnackbar }) => {
  const [hora, setHora] = useState(moment().format('HH:mm'));

  async function getData() {
    const response = await api.get('/horario_servidor')
    const dataAtualServidor = new Date(response.data.dataAtual)
    setHora(moment(dataAtualServidor).format('HH:mm'))
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      try{
        getData()
      } catch(e) {
        enqueueSnackbar('INTERNET: ERRO AO BUSCAR O HORARIO DO SERVIDOR!', {
          variant: 'error',
        });
      }
    }, 1000 * 60);
    return () => clearTimeout(timer);
  });

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <span style={{ fontWeight: 'bold' }}>Hora: </span>
      <span>{hora}</span>
    </div>
  );
};

export default Temporizador;
