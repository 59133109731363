import axios from 'axios';

import { createQueryURL, URL_SERVIDOR_IMPRESSORA } from '../utils';

import {
  getModoOffline
} from './config'

const HOST_API = process.env.REACT_APP_HOST_API;
const HOST_API_OFFLINE = URL_SERVIDOR_IMPRESSORA;

const api = axios.create({
  baseURL: HOST_API,
});

api.interceptors.request.use(config => {
  config.baseURL = getModoOffline() ? HOST_API_OFFLINE : HOST_API
  return config
}, error => Promise.reject(error))

export default api;

export async function getListAPI(resorce, sort, range, filter, nested) {
  const url = `/${resorce}?${createQueryURL(sort, range, filter, nested)}`;
  const data = await api.get(url);
  return {
    data: data.data.data,
    total: parseInt(data.data.total, 10),
  };
}

export async function getListAllAPI(resorce, sort, filter, nested) {
  const url = `/${resorce}/all?${createQueryURL(sort, null, filter, nested)}`;
  const data = await api.get(url);
  return {
    data: data.data,
  };
}

export async function getOneAPI(resorce, id, nested) {
  const url = `/${resorce}/${id}?${createQueryURL(null, null, null, nested)}`;
  const data = await api.get(url);
  return {
    data: data.data,
  };
}

export async function getManyAPI(resorce, filter, nested) {
  const url = `/${resorce}?${createQueryURL(null, null, filter, nested)}`;
  const data = await api.get(url);
  return {
    data: data.data,
  };
}

export async function updateAPI(resorce, id, body) {
  const url = `/${resorce}/${id}`;
  const data = await api.put(url, body);
  return {
    data: data.data,
  };
}

export async function updateManyAPI(resorce, filter, body) {
  const url = `/${resorce}?${createQueryURL(null, null, filter, null)}`;
  const data = await api.put(url, body);
  return {
    data: data.data,
  };
}

export async function deleteAPI(resorce, id) {
  const url = `/${resorce}/${id}`;
  const data = await api.delete(url);
  return {
    data: data.data,
  };
}

export async function deleteManyAPI(resorce, filter) {
  const url = `/${resorce}?${createQueryURL(null, null, filter, null)}`;
  const data = await api.delete(url);
  return {
    data: data.data,
  };
}

export async function createAPI(resorce, body) {
  const url = `/${resorce}`;
  const data = await api.post(url, body);
  return {
    data: data.data,
  };
}
