import React from 'react';
import { Route } from 'react-router-dom';

import Background from './Background';

const RouteBackground = ({
  path,
  component,
  exact = false,
  isInit = false,
}) => {
  const Component = component;
  return (
    <>
      <Route
        path={path}
        exact={exact}
        render={(props) => (
          <Background isInit={isInit}>
            <Component />
          </Background>
        )}
      />

    </>
  );
};

export default RouteBackground;
